import { Component, Inject } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { UploadService } from '../upload.service';
import { BehaviorSubject } from 'rxjs';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SettingsService } from '@common/core/settings/settings.service';
import { File as FileEntry } from 'src/app/models/file';
import { Toast } from '@common/core/toast/toast/toast.service';
import { CropperComponent } from '@common/core/images/cropper/cropper.component';

@Component({
  selector: 'upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
})
export class UploadDialogComponent {
  constructor(
    public uploads: UploadService,
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public settings: SettingsService,
    public toast: Toast,
    private dialog: MatDialog
  ) {}

  public finishedUploads$ = new BehaviorSubject<any[]>([]);
  public files: NgxFileDropEntry[] = [];
  public filteredFiles: File[] = [];

  public dropped(files: NgxFileDropEntry[]) {
    let arr = files.filter((file) => file.fileEntry.isFile);

    let i = 0;
    if (!this.data?.multiple && arr.length > 1) {
      arr = arr.slice(0, 1);
    }
    this.files = arr.filter((file) => file.fileEntry.isFile);
    for (let file of arr) {
      const fileEntry = file.fileEntry as FileSystemFileEntry;
      const file1 = file;
      fileEntry.file((file: File) => {
        const acceptedMimeTypes = this.settings.get(
          'uploads.acceptedMimeTypes.' + (this.data.type || 'image'),
          ['image/png', 'image/jpeg', 'image/jpg', 'image/webp']
        );
        console.log(acceptedMimeTypes);
        if (this.data.type != 'all' && ( !acceptedMimeTypes || !acceptedMimeTypes.includes(file.type))) {
          this.toast.open('Dosya türü desteklenmiyor.');
          this.files = this.files.filter((f) => f !== file1);
          return;
        }

        this.filteredFiles.push(file);
        console.log(this.filteredFiles, file, i, this.data);
        if (this.filteredFiles.length == 1 && file.type.includes('image') && this.data.allowCrop) {
          const ref = this.dialog.open(CropperComponent, {
            data: {
              file: file,
              aspectRatio: this.data.aspectRatio || 1,
            },
          });
          ref.afterClosed().subscribe((blob) => {
            if (blob) {
              this.upload(this.filteredFiles, file, blob, i);
            } else {
              this.dialogRef.close();
            }
          });
        } else {
          this.upload(this.filteredFiles, file, null, i);
        }
      });
    }
  }

  public upload(filteredFiles: File[], file: File, blob: Blob, i: number) {
    this.uploads.upload(
      blob
        ? {
            name: file.name,
            type: file.type,
            blob: blob,
          }
        : file,
      (id: string, fileEntry: FileEntry) => {
        const value = this.finishedUploads$.value;
        value.push(fileEntry);
        this.finishedUploads$.next(value);
        if (i === filteredFiles.length - 1) {
          this.finishedUploads$.complete();
        }
        if (filteredFiles.length === 1) {
          this.dialogRef.close(this.finishedUploads$.value);
        }
        i++;
      }
    );
  }
}
