import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { Toast } from '@common/core/toast/toast/toast.service';
import { UploadService } from '@common/core/upload/upload.service';
import { MembersService } from '@common/services/members.service';
import { ProfileService } from '@common/services/profile.service';
import { ProjectsService } from '@common/services/projects.service';
import { RolesService } from '@common/services/roles.service';
import { Members } from 'src/app/models/relations/members';
import { AddProjectDialogComponent } from '../add-project-dialog/add-project-dialog.component';

@Component({
  selector: 'add-role-dialog',
  templateUrl: './add-role-dialog.component.html',
  styleUrls: ['./add-role-dialog.component.scss'],
})
export class AddRoleDialogComponent implements AfterViewInit, OnInit {
  constructor(
    public ref: MatDialogRef<AddProjectDialogComponent>,
    public fb: FormBuilder,
    public uploads: UploadService,
    public projects: ProjectsService,
    public router: Router,
    public auth: AuthService,
    public profiles: ProfileService,
    public toast: Toast,
    public roles: RolesService,
    public memberService: MembersService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public loading = false;

  colors: any[] = [
    {
      id: 1,
      color: '#4B53F2',
    },
    {
      id: 2,
      color: '#7A59A4',
    },
    {
      id: 3,
      color: '#D6336C',
    },
    {
      id: 4,
      color: '#F03E3E',
    },
    {
      id: 5,
      color: '#F76707',
    },
    {
      id: 6,
      color: '#F59F00',
    },
    {
      id: 7,
      color: '#37B24D',
    },
    {
      id: 8,
      color: '#0CA678',
    },
    {
      id: 9,
      color: '#74B816',
    },
    {
      id: 10,
      color: '#40E0D0',
    },
    {
      id: 11,
      color: '#495057',
    },
  ];

  @ViewChild('stepper') public stepper: MatStepper;
  @ViewChild('editor') public editor;

  members: Members[] = [];

  public generalGroup = this.fb.group({
    id: [''],
    name: ['', Validators.required],
    description: ['', Validators.required],
    type: ['', Validators.required],
    color: ['#F59F00', Validators.required],
  });

  public permissionGroup = this.fb.group({
    permissions: [],
  });

  deleteMemberOnRole(member: Members) {
    this.memberService.deleteMember(member.memberId).subscribe({
      next: (res) => {
        if (res.success) {
          this.toast.open('Üye Silindi');
          this.getRoleMembers(this.data.id);
        }
      },
      error: (err: HttpErrorResponse) => {
        console.error('Üye Silinemedi', err.error);
        this.toast.open('Üye Silinemedi');
      },
    });
  }

  getRoleMembers(roleId: string) {
    this.roles.getMembersOnRole(roleId).subscribe({
      next: (res) => {
        if (res.success) {
          this.members = res.data;
        } else {
          this.members = [];
        }
      },
      error: (err: HttpErrorResponse) => {
        console.error('Üyeler Getirilemedi', err.error);
        this.members = [];
      },
    });
  }

  public canSubmit() {
    return this.generalGroup.valid;
  }

  public getObservable(formData: any) {
    if (this.data?.id) {
      return this.roles.editRole(this.data.id, formData);
    } else {
      return this.roles.createRole(formData);
    }
  }

  public submit() {
    if (!this.canSubmit()) return;
    const formData = {
      ...this.generalGroup.value,
      permissions: this.permissionGroup.value.permissions
        ?.filter((item) => item?.permission)
        .map((item) => item.permission),
    };
    console.log(formData);
    this.getObservable(formData).subscribe((data) => {
      this.toast.open('Rol Kaydedildi');
      this.ref.close(data.data);
    });
  }

  public upperFirstLetter(value: string): any {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  ngOnInit(): void {
    if (this.data) {
      this.generalGroup.patchValue({
        id: this.data.id,
        name: this.data.name,
        description: this.data.description,
        type: this.data.type,
      });

      if (this.data.permissions) {
        this.permissionGroup.patchValue({
          permissions: this.data.permissions || [],
        });
      }

      if (this.data.id) {
        this.getRoleMembers(this.data.id);
      }
    }
  }

  public currentIndex = 0;
  ngAfterViewInit(): void {
    this.stepper.selectionChange.subscribe((data) => {
      this.currentIndex = data.selectedIndex;
      console.log(data);
    });
  }
}
