import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateHour'
})
export class DateHourPipe implements PipeTransform {

  transform(value: Date | undefined | null, ...args: any[]): string {
    if (value) {
      const date = new Date(Date.parse(value?.toString()))  ;
      date.setHours(date.getHours());
      let hour:any = date.getHours();
      let minute:any = date.getMinutes();
      if (hour < 10) {
        hour = `0${hour}`;
      }

      if (minute < 10) {
        minute = `0${minute}`;
      }
      return `${hour}:${minute}`;
    }
    return ""
  }

}
