<mat-divider></mat-divider>
<div class="footer">
    <div class="footer-icon">
      <a class="like-button" [ngClass]="{'liked' : liked}" (click)="toggleLike()"> <mat-icon>{{ liked ? 'favorite' : 'favorite_border' }}</mat-icon></a> 
    </div>
    
    <div class="footer-divider"></div>
    <div class="footer-icon">
      <a class="save-button" [ngClass]="{'saved':saved}" (click)="toggleSave()"><mat-icon>{{saved ? 'bookmark' : 'turned_in_not'}}</mat-icon></a>
    </div>
    <div class="footer-divider"></div>
    <div class="footer-icon">
      <a class="more" [matMenuTriggerFor]="menu"><mat-icon>more_horiz</mat-icon></a>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>İlgilenmiyorum</button>
        <button mat-menu-item>Şikayet Et</button>
      </mat-menu>
    </div>
  </div>
  