import { Component, ViewChild, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextEditorComponent } from '../text-editor.component';

@Component({
  selector: 'text-editor-form',
  templateUrl: './text-editor-form.component.html',
  styleUrls: ['./text-editor-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextEditorFormComponent),
      multi: true,
    },
  ],
})
export class TextEditorFormComponent implements ControlValueAccessor , OnDestroy{
  @ViewChild('editor', {
    static: true,
  })
  editor: TextEditorComponent;

  public content = '';

  propagateChange = (_: any) => {};

  writeValue(obj: string): void {
    this.content = obj;
    console.log(obj);
    if (this.editor) {
      this.editor.setContents(obj);
    }
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  public onChange() {
    this.propagateChange(this.editor.getContents());
  }

  public execCommand(command: string) {
    this.editor.execCommand(command);
  }

  ngOnDestroy(): void {
    
  }
}
