<h1 mat-dialog-title>İlan Oluştur</h1>
<div mat-dialog-content style="width: 100%">
  <mat-stepper orientation="horizontal" [linear]="true" #stepper>
    <mat-step [stepControl]="generalGroup">
      <form [formGroup]="generalGroup">
        <ng-template matStepLabel>Genel Bilgiler</ng-template>
        <div>
          <h2>İlanınızın ismi ne olsun?</h2>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>İsim</mat-label>
            <input matInput placeholder="" formControlName="title" required />
          </mat-form-field>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="generalGroup">
      <form [formGroup]="generalGroup">
        <ng-template matStepLabel>Etiketler</ng-template>
        <div>
          <h2>İlanınızı tanımlayan etkiketler girin.</h2>
          <tag-input style="width: 100%" [tags]="tags"></tag-input>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="generalGroup">
      <form [formGroup]="contentGroup">
        <ng-template matStepLabel>İçerik</ng-template>
        <div>
          <h2>İlanınıza güzel bir açıklama girelim.</h2>

          <text-editor-form formControlName="description"></text-editor-form>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="timeGroup">
      <form [formGroup]="timeGroup">
        <ng-template matStepLabel>Tarihler</ng-template>
        <div>
          <h2>İlanınızın başlangıç tarihini.</h2>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Başlangıç Tarihi</mat-label>
            <input
              matInput
              formControlName="startDate"
              [matDatepicker]="startPicker"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="startPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </mat-form-field>
        </div>
      </form>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Mutlu son</ng-template>
      <h1>Neredeyse bitti.</h1>
      <p>Kalan kısımları hallediyoruz...</p>
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
    </mat-step>
  </mat-stepper>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">İptal</button>
  <button mat-button (click)="stepper.previous()">Önceki</button>
  <button
    mat-button
    cdkFocusInitial
    (click)="stepper.next()"
    *ngIf="!canSubmit()"
  >
    Sonraki
  </button>
  <button mat-button cdkFocusInitial (click)="submit()" *ngIf="canSubmit()">
    Oluştur
  </button>
</div>
