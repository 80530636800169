<div class="find-box">
  <mat-card appearance="outlined" class="mat-elevation-z0 card">
    <div class="card-content">
      <div class="icon-panel">
        <mat-icon>{{ icon }}</mat-icon>
      </div>
      <div class="main-panel">
        <div>
          <h4>{{ title }}</h4>
          <p [innerHTML]="text"></p>
        </div>
      </div>
      <div class="actions-panel">
        <button
          mat-flat-button
          color="primary"
          class="action"
          (click)="click()"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </mat-card>
</div>
