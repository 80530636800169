<mat-form-field [appearance]="apperaance" class="example-chip-list" [style.width]="width">
    <mat-label>Etiketler</mat-label>
    <mat-chip-grid #chipGrid aria-label="Etiket girin">
      <mat-chip-row
        *ngFor="let tag of tags.controls"
        (removed)="remove(tag.value)"
        [editable]="true"
        (edited)="edit(tag.value, $event)"
        [aria-description]="'press enter to edit ' + tag.value.name"
      >
        {{ tag.value.name }}
        <button
          matChipRemove
          [attr.aria-label]="'remove ' + tag.value.name"
        >
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
      <input
        placeholder="Yeni etiket..."
        [matChipInputFor]="chipGrid"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-grid>
  </mat-form-field>