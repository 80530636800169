import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { ObjectId } from '@common/core/utils/object-id';
import { Observable } from 'rxjs';
import { Members } from 'src/app/models/relations/members';
import { Role } from 'src/app/models/role';
import { User } from 'src/app/models/user';

const BASE = 'roles';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  constructor(private http: AppHttpClient) {}

  public listRoles(type: string) {
    return this.http.get<StandartResponse<Role[]>>(`${BASE}/${type}`);
  }

  getRoles() {
    return this.http.get<StandartResponse<Role[]>>(`${BASE}/`);
  }

  public createRole(data: any) {
    return this.http.post<StandartResponse<Role>>(`${BASE}`, data);
  }

  public editRole(id: string, data: any) {
    return this.http.put<StandartResponse<Role>>(`${BASE}/${id}`, data);
  }

  deleteRole(roleId: ObjectId): Observable<StandartResponse<Role>> {
    return this.http.delete(`${BASE}/${roleId}`);
  }
  getMembersOnRole(roleId: ObjectId): Observable<StandartResponse<Members[]>> {
    return this.http.get(`${BASE}/${roleId}/members`);
  }
}
