<mat-card
  appearance="outlined"
  class="events-card"
  [ngClass]="event.themeColor || 'purple'"
  routerLinkActive="active"
  [class.fullHeight]="fullHeight"
>
  <div class="events-container d-flex" style="height: 100%;">
    <div class="color"></div>
    <div class="event d-flex flex-column justify-content-between" >
      <div class="pointer" [routerLink]='disableClick ? undefined : "/event/" + event.id'>
        <h2>{{ event.name }}</h2>
      <p>
        {{ event.headnote }}
      </p>
      </div>
      <div class="eventContent pointer" [class.spaceTop]="!hideDetails" [routerLink]='disableClick ? undefined : "/event/" + event.id'>
        <div class="creater" *ngIf="!hideDetails">
          <div class="logos">
            <img
              *ngFor="let contributor of event.contributors"
              [src]="contributor.profile.avatar"
            />
          </div>
          <p *ngIf="event.contributors?.length">
            <b *ngFor="let contributor of event.contributors; let i = index"
              >{{ contributor.profile.display_name || contributor.profile.name}}
              <ng-container *ngIf="i < event.contributors.length - 1"
                >,
              </ng-container></b
            >
            tarafından düzenleniyor
          </p>
          <p *ngIf="!event.contributors?.length && event?.organizator">
            <b 
              >{{event.organizator?.name}}</b
            >
            tarafından düzenleniyor
          </p>
        </div>
        <div class="date">
          <mat-icon>calendar_month</mat-icon>
          <p>{{ event.startDate | dateDayMonth }}</p>
        </div>
        <div class="time">
          <mat-icon>schedule</mat-icon>
          <p>{{ event.startDate | dateHour }}</p>
        </div>
        <div class="location">
          <mat-icon>location_on</mat-icon>
          <p>{{ event.location }}</p>
        </div>
      </div>
      <div class="eventActions" [class.spaceTop]="!hideDetails">
        <div class="description">
          
        </div>
        <div class="buttons">
          <button class="primary" *ngIf="hasTicket" mat-flat-button>
            <mat-icon>done</mat-icon>
            {{ticketText}}
          </button>
          <button
            class="primary"
            *ngIf="!hasTicket"
            mat-flat-button
            (click)="buyTicket()"
          >
            <mat-icon>done</mat-icon>
            {{ticketText}}
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-card>
