import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Toast } from '@common/core/toast/toast/toast.service';
import { FeedbackOpenerServiceService } from '@common/services/feedback-opener-service.service';
import { FeedbackService } from '@common/services/feedback.service';

@Component({
  selector: 'feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
})
export class FeedbackFormComponent implements OnInit {
  tagFormHolder: FormGroup[] = [];
  mainForm: FormGroup;
  tagForm: FormGroup;

  constructor(
    public fb: FormBuilder,
    private feedBackService: FeedbackService,
    private feedbackOpener: FeedbackOpenerServiceService,
    private toast: Toast,
    private ref: MatBottomSheetRef<FeedbackFormComponent>
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.mainForm = this.fb.group({
      pages: new FormControl([], Validators.required),
      tag: new FormControl(null, Validators.required),
      additionalText: new FormControl(null),
    });
    this.tagForm = this.fb.group({
      name: new FormControl(null, Validators.required),
    });
  }

  addTags() {
    const form: FormGroup = this.fb.group({
      name: new FormControl(this.tagForm.controls['name'].value),
    });

    this.tagFormHolder.push(form);

    this.tagForm.reset();
  }
  deleteTags(form: FormGroup) {
    this.tagFormHolder = this.tagFormHolder.filter((x) => x !== form);
  }

  submitForm() {
    const nameArray = [];
    this.tagFormHolder.forEach((form) => {
      const name = { name: form.controls['name'].value };
      nameArray.push(name);
    });
    this.mainForm.controls['pages'].setValue(nameArray);
    this.feedBackService.post(this.mainForm.value).subscribe({
      next: (res) => {
        this.toast.open('Geri bildiriminiz için teşekkür ederiz.');
        this.ref.dismiss();
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.toast.open('Bir hata oluştu.');
      },
    });
  }
}
