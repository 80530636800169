<div class="editor-header">
    <div class="toolbar-row">
        <div class="header-actions" *ngIf="inlineUploadPrefix && currentUser.hasPermission('files.create') && onFileUpload.observers.length">
            <button class="no-style editor-button" type="button" [matTooltip]="'Add Attachment' | trans"
                    (click)="openFileUploadDialog()">
                <mat-icon>attachment</mat-icon>
            </button>
        </div>

        <div class="header-actions" *ngIf="!basic">
            <button type="button" class="no-style editor-button" (click)="execCommand('codesample')"
                    [matTooltip]="'Insert Code Sample' | trans">
                <mat-icon>code</mat-icon>
            </button>
        </div>

        <div class="header-actions">
            <button type="button" class="no-style editor-button" (click)="execCommand('undo')"
                    [class.disabled]="!hasUndo()" [matTooltip]="'Undo' | trans">
                <mat-icon >undo</mat-icon>
            </button>
            <button type="button" class="no-style editor-button" (click)="execCommand('redo')"
                    [class.disabled]="!hasRedo()" [matTooltip]="'Redo' | trans">
                <mat-icon>redo</mat-icon>
            </button>
        </div>

        <div class="header-actions">
            <button type="button" class="no-style editor-button" (click)="execCommand('bold')"
                    [class.active]="queryCommandState('bold')" [matTooltip]="'Bold' | trans">
                <mat-icon>format_bold</mat-icon>
            </button>
            <button type="button" class="no-style editor-button" (click)="execCommand('italic')"
                    [class.active]="queryCommandState('italic')" [matTooltip]="'Italic' | trans">
                <mat-icon >format_italic</mat-icon>
            </button>
            <button type="button" class="no-style editor-button" (click)="execCommand('underline')"
                    [class.active]="queryCommandState('underline')" [matTooltip]="'Underline' | trans">
                <mat-icon >format_underlined</mat-icon>
            </button>
        </div>

        <div class="header-actions">
            <button type="button" class="no-style editor-button" (click)="execCommand('insertunorderedlist')"
                    [class.active]="queryCommandState('unorderedlist')" [matTooltip]="'Unordered List' | trans">
                <mat-icon>format_list_bulleted</mat-icon>
            </button>
            <button type="button" class="no-style editor-button" (click)="execCommand('insertorderedlist')"
                    [class.active]="queryCommandState('orderedlist')" [matTooltip]="'Ordered List' | trans">
                <mat-icon>format_list_numbered</mat-icon>
            </button>
        </div>

        <div class="header-actions" *ngIf="!basic">
            <button type="button" class="no-style editor-button" (click)="execCommand('mceLink')"
                    [class.active]="queryCommandState('link')" [matTooltip]="'Insert Link' | trans">
                <mat-icon>link</mat-icon>
            </button>
            <button type="button" class="no-style editor-button image-modal-button" (click)="openInsertImageModal()"
                    *ngIf="currentUser.hasPermission('files.create')" [matTooltip]="'Insert Image' | trans">
                <mat-icon>image</mat-icon>
            </button>
        </div>

        <ng-content select="[customButtons]"></ng-content>

        <div class="header-actions last">
            <button type="button" class="no-style editor-button" (click)="execCommand('removeformat')"
                    [matTooltip]="'Remove Formatting' | trans">
                <mat-icon>format_clear</mat-icon>
            </button>
        </div>

        <ng-content select="[header]"></ng-content>
    </div>
    <div class="toolbar-row">
        <div class="header-actions">
            <button type="button" [matMenuTriggerFor]="insertMenu"
                    class="no-style editor-button text-button insert-button">
                <span trans>Yerleştir</span>
                <mat-icon class="caret">arrow_drop_down</mat-icon>
            </button>
            <mat-menu #insertMenu>
                <div mat-menu-item (click)="execCommand('InsertHorizontalRule')">
                    <mat-icon>remove</mat-icon>
                    <span trans>Horizontal Line</span>
                </div>
                <div mat-menu-item (click)="execCommand('mceLink')">
                    <mat-icon>link</mat-icon>
                    <span trans>Link</span>
                </div>
                <div mat-menu-item (click)="execCommand('mceMedia')">
                    <mat-icon>videocam</mat-icon>
                    <span trans>Media</span>
                </div>
                <div mat-menu-item (click)="insertInfoContainer('important')" trans>
                    <mat-icon >star</mat-icon>
                    <span trans>Important</span>
                </div>
                <div mat-menu-item (click)="insertInfoContainer('warning')" trans>
                    <mat-icon>warning</mat-icon>
                    <span trans>Warning</span>
                </div>
                <div mat-menu-item (click)="insertInfoContainer('note')" trans>
                    <mat-icon>note</mat-icon>
                    <span trans>Note</span>
                </div>
            </mat-menu>
        </div>
        <div class="header-actions">
            <button type="button" [matMenuTriggerFor]="formatMenu" class="no-style editor-button text-button format-button">
                <span trans>Format</span>
                <mat-icon class="caret">arrow_drop_down</mat-icon>
            </button>
            <mat-menu #formatMenu class="format-menu menu-with-alt-text">
                <div mat-menu-item (click)="execCommand('FormatBlock', 'h1')">
                    <div class="text" trans>Başlık 1</div>
                    <div class="alt-text">Alt+Shift+1</div>
                </div>
                <div mat-menu-item (click)="execCommand('FormatBlock', 'h2')">
                    <div class="text" trans>Başlık 2</div>
                    <div class="alt-text">Alt+Shift+2</div>
                </div>
                <div mat-menu-item (click)="execCommand('FormatBlock', 'h3')">
                    <div class="text" trans>Başlık 3</div>
                    <div class="alt-text">Alt+Shift+3</div>
                </div>
                <div mat-menu-item (click)="execCommand('FormatBlock', 'h4')">
                    <div class="text" trans>Başlık 4</div>
                    <div class="alt-text">Alt+Shift+4</div>
                </div>
                <div mat-menu-item (click)="execCommand('FormatBlock', 'code')">
                    <div class="text" trans>Kod</div>
                </div>
                <div mat-menu-item (click)="execCommand('strikethrough')">
                    <div class="text" trans>Üstü Çizgili</div>
                </div>
                <div mat-menu-item (click)="execCommand('superscript')">
                    <div class="text" trans>Üst Simge</div>
                </div>
                <div mat-menu-item (click)="execCommand('subscript')">
                    <div class="text" trans>Alt simge</div>
                </div>
                <div mat-menu-item (click)="execCommand('mceBlockQuote')">
                    <div class="text" trans>Alıntı</div>
                </div>
                <div mat-menu-item (click)="execCommand('FormatBlock', 'p')">
                    <div class="text" trans>Paragraf</div>
                    <div class="alt-text">Ctrl+Shift+7</div>
                </div>
            </mat-menu>
        </div>
        <div class="header-actions">
            <button type="button" [matMenuTriggerFor]="colorMenu" class="no-style editor-button text-button color-button" #colorPickerOrigin>
                <span trans>Renkler</span>
                <mat-icon class="caret">arrow_drop_down</mat-icon>
            </button>
            <mat-menu #colorMenu class="color-dropdown">
                <label for="ForeColor" mat-menu-item class="text-color-item">
                    <mat-icon>format_color_text</mat-icon>
                    <span trans>Yazı Rengi</span>
                </label>
                <label for="HiliteColor" mat-menu-item class="bg-color-item">
                    <mat-icon>format_color_fill</mat-icon>
                    <span trans>Arka plan Rengi</span>
                </label>
            </mat-menu>
        </div>
        <div style="opacity: 0;">
            <input id="ForeColor" type="color" #colorPicker (input)="execCommand('ForeColor', colorPicker.value)">
            <input id="HiliteColor" type="color" #bgColorPicker (input)="execCommand('HiliteColor', bgColorPicker.value)">
        </div>
        <div class="header-actions">
            <button type="button" class="no-style editor-button" (click)="execCommand('justifyLeft')"
                    [class.active]="queryCommandState('justifyLeft')" [matTooltip]="'Align Left' | trans">
                <mat-icon >format_align_left</mat-icon>
            </button>
            <button type="button" class="no-style editor-button" (click)="execCommand('justifyCenter')"
                    [class.active]="queryCommandState('justifyCenter')" [matTooltip]="'Center' | trans">
                <mat-icon>format_align_center</mat-icon>
            </button>
            <button type="button" class="no-style editor-button" (click)="execCommand('justifyRight')"
                    [class.active]="queryCommandState('justifyRight')" [matTooltip]="'Align Right' | trans">
                <mat-icon>format_align_right</mat-icon>
            </button>
        </div>
        <div class="header-actions">
            <button type="button" class="no-style editor-button" (click)="execCommand('Outdent')" [matTooltip]="'Decrease Indent' | trans">
                <mat-icon>format_indent_decrease</mat-icon>
            </button>
            <button type="button" class="no-style editor-button" (click)="execCommand('Indent')" [matTooltip]="'Increase Indent' | trans">
                <mat-icon>format_indent_increase</mat-icon>
            </button>
        </div>
        <div class="header-actions editor-toggle-buttons">
            <button type="button" class="no-style editor-button visual-mode-button"
                    [class.active]="activeEditor === 'visual'" (click)="showVisualEditor()" trans>Görsel
            </button>
            <button type="button" class="no-style editor-button source-mode-button"
                    [class.active]="activeEditor === 'source'" (click)="showSourceEditor()" trans>Kaynak Kod
            </button>
        </div>
    </div>
</div>

<textarea class="source-textarea" #sourceArea rows="8" [style.min-height]="minHeight" style="display: none" [formControl]="sourceAreaControl" (blur)="setContents(sourceAreaControl.value)"></textarea>
<textarea class="visual-textarea" #visualArea rows="8" [style.min-height]="minHeight"></textarea>

<ng-content select="[footer]"></ng-content>
