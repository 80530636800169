import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { Observable } from 'tinymce';
const BASE = 'feedback';

export interface EarlyAccessData {
  pages: PageName[];
  tag: string;
  additionalText: string;
}
export interface PageName {
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private http: AppHttpClient) {}

  post(formData: EarlyAccessData) {
    return this.http.post<any>(BASE + '/early-access', formData);
  }
}
