<mat-selection-list #permissions>
  <mat-list-option (click)="toggleAll()">
    Tümünü Seç
  </mat-list-option>
  <ng-container *ngFor="let category of categories">
    <div mat-subheader>{{ category.name }}</div>
    <mat-list-option *ngFor="let item of category.items" [value]="item" [selected]="selectedPermissions.includes(item.permission)">
      {{ item.name }}
    </mat-list-option>
  </ng-container>
</mat-selection-list>
