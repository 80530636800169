import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Toast } from '@common/core/toast/toast/toast.service';
import { EventsService } from '@common/services/events.service';

@Component({
  selector: 'add-event-application-dialog',
  templateUrl: './add-event-application-dialog.component.html',
  styleUrls: ['./add-event-application-dialog.component.scss'],
})
export class AddEventApplicationDialogComponent implements OnInit {
  constructor(
    public fb: FormBuilder,
    private events: EventsService,
    private ref: MatDialogRef<AddEventApplicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      eventId: string;
      oldValue?: any;
    },
    private toast: Toast
  ) {}

  public generalGroup = this.fb.group({
    name: ['', Validators.required],
    description: ['', Validators.required],
    location: [''],
    hasEndDate: [false],
    endDate: [''],
    hasMaxTickets: [false],
    maxTickets: [0],
  });

  public submit() {
    if (!this.generalGroup.value.hasMaxTickets) {
      this.generalGroup.get('maxTickets').setValue(0);
    }

    if (!this.generalGroup.value.hasEndDate) {
      this.generalGroup.get('endDate').setValue(null);
    }

    if (this.data.oldValue) {
      this.events
        .updateApplication(
          this.data.eventId,
          this.data.oldValue.id,
          this.generalGroup.value
        )
        .subscribe((response) => {
          this.ref.close(response.data);
          this.toast.open('Bilet güncellendi.');
        });
    } else {
      this.events
        .addApplication(this.data.eventId, this.generalGroup.value)
        .subscribe((response) => {
          this.ref.close(response.data);
          this.toast.open('Bilet oluşturuldu.');
        });
    }
  }

  ngOnInit(): void {
    if (this.data.oldValue) {
      this.generalGroup.patchValue(this.data.oldValue);
    }
  }
}
