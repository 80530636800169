import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CropperComponent } from './cropper/cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { HammerModule } from '@angular/platform-browser';



@NgModule({
  declarations: [
    CropperComponent
  ],
  imports: [
    CommonModule,
    ImageCropperModule,
    MatProgressBarModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    HammerModule
    
  ],
  exports: [
    CropperComponent
  ]
})
export class ImagesModule { }
