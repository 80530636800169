import { Component, Input } from '@angular/core';
import { PaginatorService } from '@common/services/paginator.service';

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
  @Input({
    required: true,
  })
  public key: string;

  constructor(public paginator: PaginatorService) {}
}
