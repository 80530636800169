<div class="list-container">
  <mat-nav-list role="list" class="mat-list f11" name="button">
    <a
      routerLink="/home"
      routerLinkActive="home"
      mat-list-item
      [activated]="currentRoute.isActive('home')"
    >
      <mat-icon matListItemIcon style="size: 16px">explore</mat-icon>
      <div mat-line>Keşif</div>
    </a>
    <a
      routerLink="/discovery-catalog"
      mat-list-item
      [activated]="currentRoute.isActive('discovery-catalog')"
    >
      <mat-icon matListItemIcon>auto_stories</mat-icon>
      <div mat-line>Katalog</div>
    </a>
    <a
      routerLink="/people"
      mat-list-item
      [activated]="currentRoute.isActive('people')"
    >
      <mat-icon matListItemIcon class="material-icons-outlined">group</mat-icon>
      <div mat-line>Kişiler</div>
    </a>

    <a
      routerLink="/communities"
      mat-list-item
      [activated]="currentRoute.isActive('communities')"
    >
      <mat-icon
        matListItemIcon
        class="mat-icon"
        fontSet="material-icons-outlined"
        >groups3</mat-icon
      >
      <div mat-line class="mat-line">Topluluklar</div>
    </a>

    <a
      routerLink="/companies"
      mat-list-item
      [activated]="currentRoute.isActive('companies')"
    >
      <mat-icon matListItemIcon fontSet="material-icons-outlined"
        >factory</mat-icon
      >
      <div mat-line>Şirketler</div>
    </a>
    <a
      routerLink="/projects"
      mat-list-item
      [activated]="currentRoute.isActive('projects')"
    >
      <mat-icon matListItemIcon>lightbulb</mat-icon>
      <div mat-line>Projeler</div>
    </a>
    <a
      routerLink="/events"
      mat-list-item
      [activated]="currentRoute.isActive('events')"
    >
      <mat-icon matListItemIcon>event</mat-icon>
      <div mat-line>Etkinlikler</div>
    </a>
    <a
      routerLink="/job-posting"
      mat-list-item
      [activated]="currentRoute.isActive('jobPosting')"
    >
      <mat-icon matListItemIcon>work</mat-icon>
      <div mat-line>İlanlar</div>
    </a>
    <a
      routerLink="/blog"
      mat-list-item
      [activated]="currentRoute.isActive('blog')"
    >
      <mat-icon matListItemIcon>article</mat-icon>
      <div mat-line>Blog</div>
    </a>
    <a
      routerLink="/calendar/list"
      mat-list-item
      [activated]="currentRoute.isActive('calendar')"
    >
      <mat-icon matListItemIcon>calendar_month</mat-icon>
      <div mat-line>Takvim</div>
    </a>
  </mat-nav-list>

  <mat-action-list class="mat-list-footer">
    <button
      (click)="openBottomFeedBackSheet()"
      *ngIf="auth.loggedIn"
      type="button"
      class="btn primaryButton"
      style="margin-bottom: 20px"
    >
      <mat-icon style="padding-right: 40px">mood</mat-icon>Erken Erşim için Geri
      Dönüş Verin
    </button>

    <p>Unilocked © 2023</p>
  </mat-action-list>
</div>
