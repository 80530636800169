<h1 mat-dialog-title>Anket Ekle</h1>
<div mat-dialog-content>
  <form (submit)="addOption()" [formGroup]="form">
    <div class="container">
      <text-input
        label="Soru"
        placeholder="Bir soru girin"
        formControlName="question"
      ></text-input>
      <div class="d-flex justify-content-between flex-wrap">
        <mat-slide-toggle formControlName="allowMultipleAnswers">Birden fazla seçime izin ver</mat-slide-toggle>
        <div class="add-option">
          <button
            type="button"
            mat-button
            color="primary"
            (click)="addOption()"
          >
            Seçenek Ekle
          </button>
        </div>
      </div>
      <div class="options">
        <div *ngFor="let control of options.controls; let i = index">
          <text-input
            [label]="'Seçenek ' + (i + 1)"
            placeholder="Seçenek girin"
            [formControl]="getControl(i)"
          ></text-input>
        </div>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button type="button" mat-dialog-close="" mat-button>İptal</button>
  <button
    type="button"
    mat-flat-button
    color="primary"
    cdkFocusInitial
    (click)="submit()"
  >
    Oluştur
  </button>
</div>
