import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { Community } from 'src/app/models/community';
import { Company } from 'src/app/models/company';
import { Event } from 'src/app/models/event';
import { Post } from 'src/app/models/post';
import { Project } from 'src/app/models/project';
import { User } from 'src/app/models/user';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private http: AppHttpClient) {}

  public global(query: string) {
    return this.http.get<
      StandartResponse<{
        users: User[];
        posts: Post[];
        companies: Company[];
        communities: Community[];
        projects: Project[];
        events: Event[];
      }>
    >('search/global', { q: query });
  }

  public events(query: string) {
    return this.http.get<
      StandartResponse<{
        events: Event[];
      }>
    >('search/events', {
      q: query,
    });
  }
}
