<div class="menu-content d-flex justify-content-center">
  <mat-card appearance="outlined">
    <div class="search-box" *ngIf="isMobile | async; else notMobile">
      <form [formGroup]="searchForm" (submit)="search()">
        <div class="navbar-search">
          <mat-icon class="navbar-icon">search</mat-icon>
          <input
            formControlName="query"
            placeholder="Aklınızda neler var?"
            class="navbar-input"
            type="text"
          />
        </div>
      </form>
    </div>
    <ng-template #notMobile>
      <div class="create-post">
        <button>
          <mat-icon>add</mat-icon>
          Gönderi Oluştur
        </button>
      </div>
    </ng-template>
    <div class="items-container">
      <h3>KEŞİF</h3>
      <div class="items">
        <div class="d-flex flex-wrap justify-content-around">
          <div
            class="item-box"
            routerLink="/home"
            (click)="userMenuRefService.close()"
          >
            <div class="icon">
              <mat-icon>explore</mat-icon>
            </div>
            <span>Keşif</span>
          </div>
          <div
            class="item-box"
            routerLink="/discovery-catalog"
            (click)="userMenuRefService.close()"
          >
            <div class="icon">
              <mat-icon>import_contacts</mat-icon>
            </div>
            <span>Katalog</span>
          </div>
          <div
            class="item-box"
            routerLink="/people"
            (click)="userMenuRefService.close()"
          >
            <div class="icon">
              <mat-icon>person</mat-icon>
            </div>
            <span>Kişiler</span>
          </div>
          <div
            class="item-box"
            routerLink="/communities"
            (click)="userMenuRefService.close()"
          >
            <div class="icon">
              <mat-icon>groups</mat-icon>
            </div>
            <span>Topluluklar</span>
          </div>
          <div
            class="item-box"
            routerLink="/companies"
            (click)="userMenuRefService.close()"
          >
            <div class="icon"><mat-icon>factory</mat-icon></div>
            <span>Şirketler</span>
          </div>
          <div
            class="item-box"
            routerLink="/saved"
            (click)="userMenuRefService.close()"
          >
            <div class="icon"><mat-icon>bookmark</mat-icon></div>
            <span>Kaydedilenler</span>
          </div>
          <div
            class="item-box"
            routerLink="/projects"
            (click)="userMenuRefService.close()"
          >
            <div class="icon">
              <mat-icon>lightbulb</mat-icon>
            </div>
            <span>Projeler</span>
          </div>
          <div
            class="item-box"
            routerLink="/events"
            (click)="userMenuRefService.close()"
          >
            <div class="icon">
              <mat-icon>event</mat-icon>
            </div>
            <span>Etkinlikler</span>
          </div>
          <div
            class="item-box"
            routerLink="/job-posting"
            (click)="userMenuRefService.close()"
          >
            <div class="icon">
              <mat-icon>contacts</mat-icon>
            </div>
            <span>İlanlar</span>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
