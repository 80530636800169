import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { DARK_MODE } from '@common/core/http/express.token';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppThemeService {
  constructor(
    private httpClient: HttpClient,
    @Inject(PLATFORM_ID) private platformId,
    @Optional() @Inject(DARK_MODE) private serverDarkMode: string
  ) {}

  public loggedIn = true;
  public changed$ = new BehaviorSubject<boolean>(this.darkMode);
  private get localStorageAvaible(): boolean {
    try {
      if (!isPlatformBrowser(this.platformId)) {
        return false;
      }
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
      return true;
    } catch (e) {
      return false;
    }
  }

  public get darkMode(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      if (this.localStorageAvaible) {
        return localStorage.getItem('darkMode') === 'true' && this.loggedIn;
      } else {
        return false;
      }
    } else {
      return this.serverDarkMode && this.loggedIn;
    }
  }

  public set darkMode(value: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.localStorageAvaible) {
        localStorage.setItem('darkMode', value.toString());
        this.changed$.next(value);
      }
      this.httpClient.post('/set-darkmode', { darkMode: value }).subscribe({
        next: (response) => {
          console.log(response);
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }
}
