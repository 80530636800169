import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SearchService } from '@common/services/search.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Event } from 'src/app/models/event';

@UntilDestroy()
@Component({
  selector: 'search-event',
  templateUrl: './search-event.component.html',
  styleUrl: './search-event.component.scss',
  standalone: false,
})
export class SearchEventComponent implements OnInit {
  constructor(
    public searchService: SearchService,
    public fb: FormBuilder,
    public ref: MatDialogRef<SearchEventComponent>
  ) {}

  public selectedEvent: Event;
  public events: Event[] = [];
  public loading: boolean = false;

  public searchForm = this.fb.group({
    query: [''],
  });

  public selectEvent(e:any, event: Event) {
    e.stopPropagation();
    e.preventDefault();
    this.selectedEvent = event;
  }

  ngOnInit(): void {
    this.searchForm.valueChanges.subscribe((value) => {
      this.loading = true;
      this.searchService
        .events(value.query)
        .pipe(untilDestroyed(this))
        .subscribe((data) => {
          this.events = data.data.events;
          this.loading = false;
        });
    });
  }

  public select() {
    this.ref.close(this.selectedEvent);
  }
}
