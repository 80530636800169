import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackFormComponent } from '@common/ui/feedback-form/feedback-form.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeedbackOpenerServiceService {
  
  changeStateFeedBack: BehaviorSubject<boolean> = new BehaviorSubject(null);
  constructor(
    public dialog:MatBottomSheet
  ) {}

  public open() {
    this.dialog.open(FeedbackFormComponent)
  }
}
