import { Injectable } from '@angular/core';
import { OverlayPanelRef } from '@common/ui/overlay-panel/overlay-panel-ref';

@Injectable({
  providedIn: 'root',
})
export class UserMenuRefService {
  constructor() {}

  public ref: any = null;

  public close() {
    // check has function close or dismiss
    if (this.ref && this.ref.dismiss) {
      this.ref.dismiss();
    } else if (this.ref && this.ref.close) {
      this.ref.close();
    }
  }
}
