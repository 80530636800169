<!-- Main -->
<div style="" class="d-flex flex-column align-items-center gap-3 mainWrapper">
  <!-- line -->
  <div class="line"></div>

  <!-- row -->
  <div style="width: 100%" class="row">
    <div class="col-12">
      <h2 class="header">Erken Erişim İçin Geri Dönüş Verin</h2>
    </div>
    <!-- left Side -->
    <div [formGroup]="mainForm" class="col-md-8 col-12">
      <div class="row">
        <div class="col-md-6 col-12">
          <text-input
            placeholder="Keşif"
            label="Bulunulan Sayfa"
            [disable]="true"
          ></text-input>
        </div>
        <div [formGroup]="tagForm" class="col-md-6 col-12 gap-3">
          <div class="d-flex flex-row align-items-center" style="gap: 5px">
            <text-input
              style="width: 100%"
              placeholder="Sayfa isimlerini Giriniz"
              label="İlgili Diğer Sayfalar"
              formControlName="name"
            ></text-input>
            <button
              [disabled]="tagForm.controls['name'].invalid"
              (click)="addTags()"
              mat-flat-button
              color="primary"
              style="margin-top: 30px"
              type="button"
              class="btn primaryButton"
            >
              Ekle
            </button>
          </div>
        </div>
        <!-- Eklenen Sayfalar -->
        <div class="col-12">
          <div class="tagsWrapper">
            <div *ngFor="let tag of tagFormHolder" class="tag">
              {{ tag.controls["name"].value }}
              <mat-icon (click)="deleteTags(tag)">close</mat-icon>
            </div>
          </div>
        </div>
        <!-- Sorun Etiketleri -->
        <div class="col-12">
          <div class="d-flex flex-column">
            <h3 class="mb-3">Etiketler</h3>
            <!-- selectionSide -->
            <div class="tagsWrapper">
              <div>
                <input
                  class="radioButton"
                  style="display: none"
                  type="radio"
                  name="tag"
                  value="reason"
                  id="reason-specification"
                  formControlName="tag"
                />
                <label class="menuTagItem" for="reason-specification"
                  >Özellik/Değişiklik Fikri</label
                >
              </div>
              <div>
                <input
                  class="radioButton"
                  style="display: none"
                  type="radio"
                  name="tag"
                  value="missingSpec"
                  id="missingSpec-specification"
                  formControlName="tag"
                />
                <label class="menuTagItem" for="missingSpec-specification"
                  >Eksik Özellik</label
                >
              </div>
              <div>
                <input
                  class="radioButton"
                  style="display: none"
                  type="radio"
                  name="tag"
                  value="experiment"
                  id="experiment-specification"
                  formControlName="tag"
                />
                <label class="menuTagItem" for="experiment-specification"
                  >Deneyim Problemleri</label
                >
              </div>
              <div>
                <input
                  class="radioButton"
                  style="display: none"
                  type="radio"
                  name="tag"
                  value="design"
                  id="design-specification"
                  formControlName="tag"
                />
                <label class="menuTagItem" for="design-specification"
                  >Tasarım Problemleri</label
                >
              </div>
              <div>
                <input
                  class="radioButton"
                  style="display: none"
                  type="radio"
                  name="tag"
                  value="accesibility"
                  id="accesibility-specification"
                  formControlName="tag"
                />
                <label class="menuTagItem" for="accesibility-specification"
                  >Erişilebilirlik Problemleri</label
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Açıklama -->
        <div class="col-12">
          <text-input
            placeholder="Açıklama Giriniz"
            label="Açıklama"
            formControlName="additionalText"
          ></text-input>
        </div>
        <!-- Buttons -->
        <div class="col-12">
          <div class="d-flex flex-row">
            <button
              (click)="submitForm()"
              mat-flat-button
              color="primary"
              type="button"
              class="btn primaryButton mr-3"
            >
              Geri Dönüş Yapın
            </button>
            <button
              type="button"
              class="btn textButton"
              style="margin-left: 20px"
            >
              İptal
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Right Side -->
    <div class="col-md-4 col-12">
      <div class="gradientCard">
        <h3>Unilocked’u Herkes İçin İnşa Etmemize Yardım Edin</h3>
        <span
          >Unilocked ekibi olarak Erken Erişim sürecinde bizlere katıldığınız
          için teşekkür ederiz.
          <br />
          <br />
          Unilocked’da herkes için en iyi deneyimi sunmaya çalışıyoruz ve geri
          dönüşlerinize çok değer veriyoruz.</span
        >
      </div>
    </div>
  </div>
</div>
