import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
})
export class CropperComponent implements OnInit, AfterViewInit {
  imageChangedEvent: any = '';
  croppedImage: Blob;

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<CropperComponent>
  ) {}

  imageCropped(event: any) {
    this.croppedImage = event.blob;
    // event.blob can be used to upload the cropped image
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  ngOnInit(): void {
    this.imageChangedEvent = {
      target: {
        files: [this.data.file],
      },
    };
  }

  public save() {
    this.ref.close(this.croppedImage);
  }

  ngAfterViewInit(): void {
    
  }
}
