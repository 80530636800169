import { Directive, ElementRef, inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[httpError]',
})
export class ErrorDirective implements OnInit {
  constructor(
    public el: ElementRef,
    private formGroupDirective: FormGroupDirective
  ) {}

  ngOnInit(): void {
    const control = this.formGroupDirective.control.get(this.httpError);
    const error = control?.errors?.['server'];

    // subscribe to the control's error changes
    control?.statusChanges.subscribe(() => {
      this.el.nativeElement.innerHTML = control?.errors?.['server'];
    });

    if (error) {
      this.el.nativeElement.innerHTML = error;
    }
  }

  @Input() httpError?: string;
}
