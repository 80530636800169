import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { Company } from 'src/app/models/company';
import { JobPosting } from 'src/app/models/job-posting';
import { Post } from 'src/app/models/post';
import { Profile } from 'src/app/models/profile';
import { Members } from 'src/app/models/relations/members';

const BASE = 'companies';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  constructor(private http: AppHttpClient) {}

  public getHome() {
    return this.http.get<StandartResponse<CompaniesHomeData>>(BASE);
  }

  create(value: any) {
    return this.http.post<StandartResponse<Company>>(BASE, value);
  }

  public getCompany(id: string) {
    return this.http.get<
      StandartResponse<{
        children: Company[];
        company: Company;
        members: Members[];
        jobPostings: JobPosting[];
        posts: Post[];
      }>
    >(`${BASE}/${id}`);
  }

  public getFollowed() {
    return this.http.get<StandartResponse<Company[]>>(`${BASE}/followed`);
  }
}

export interface CompaniesHomeData {
  followed: Company[];
  suggestions: Company[];
  mostFollowed: Company[];
  around: Company[];
}
