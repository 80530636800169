import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  constructor() {}

  @Input() controls = true;
  @ViewChild('slideContent') public slideContent: ElementRef<HTMLDivElement>;

  ngOnInit(): void {}

  public scrollNext() {
    console.log("next")
    this.slideContent.nativeElement.scrollLeft += 300;
  }

  public scrollPrev() {
    console.log("prev")
    this.slideContent.nativeElement.scrollLeft -= 300;
  }
  
}
