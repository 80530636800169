import { DiscorveryItem } from './../../app/models/discovery/item';
import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { BehaviorSubject } from 'rxjs';
import { Community } from 'src/app/models/community';
import { Company } from 'src/app/models/company';
import { DiscoveryCategory } from 'src/app/models/discovery/category';
import { Document } from 'src/app/models/document';
import { Post } from 'src/app/models/post';
import { University } from 'src/app/models/university';
import { User } from 'src/app/models/user';

const BASE = 'discovery-catalog';

@Injectable({
  providedIn: 'root',
})
export class DiscoveryCatalogService {
  constructor(private http: AppHttpClient) {}

  public selectedTags = new BehaviorSubject<DiscoveryCategory[]>([]);

  public removeTag(tag: DiscoveryCategory) {
    let arr = this.selectedTags.value;
    arr = arr.filter((item) => item.name !== tag.name);
    this.selectedTags.next(arr);
  }

  public toggleTag(tag: DiscoveryCategory) {
    if (this.isTagSelected(tag)) {
      this.removeTag(tag);
    } else {
      this.selectTag(tag);
    }
  }

  public selectTag(tag: DiscoveryCategory) {
    let arr = this.selectedTags.value;
    arr = arr.filter((item) => item.name !== tag.name);
    arr.push(tag);
    this.selectedTags.next(arr);
  }

  public isTagSelected(tag: DiscoveryCategory) {
    return (
      this.selectedTags.value.find((item) => item.name == tag.name) !==
      undefined
    );
  }

  public getSpecial() {
    return this.http.get<
      StandartResponse<{
        interestedIn: DiscorveryItem[];
        highGrades: DiscorveryItem[];
        popularInYourLocation: DiscorveryItem[];
      }>
    >(`${BASE}/special`);
  }

  public getTrends() {
    return this.http.get<
      StandartResponse<{
        trending: DiscorveryItem[];
      }>
    >(`${BASE}/trending`);
  }

  public getCategories() {
    return this.http.get<
      StandartResponse<{
        communities: DiscoveryCategory[];
        companies: DiscoveryCategory[];
        schools: DiscoveryCategory[];
        people: DiscoveryCategory[];
        projects: DiscoveryCategory[];
        events: DiscoveryCategory[];
        sectors: DiscoveryCategory[];
        jobPostings: DiscoveryCategory[];
      }>
    >(`${BASE}/categories`);
  }

  public searchByCategories(selectedCategories: string[]) {
    return this.http.get<
      StandartResponse<{
        trending: DiscorveryItem[];
        partners: DiscorveryItem[];
        newCompanies: DiscorveryItem[];
        localCountries: DiscorveryItem[];
        companiesWithJobPostings: DiscorveryItem[];
      }>
    >(`${BASE}/search-categories`, {
      selectedCategories,
    });
  }

  public getFollowing() {
    return this.http.get<
      StandartResponse<{
        projectsFromCommunities: DiscorveryItem[];
        followedJobPosts: DiscorveryItem[];
        projectsFromFollowed: DiscorveryItem[];
        favoriteCategories: DiscorveryItem[];
        eventsFromFollowed: DiscorveryItem[];
      }>
    >(`${BASE}/following`);
  }

  public getSaved(filterType: SAVED_FILTER_TYPE = SAVED_FILTER_TYPE.ALL) {
    return this.http.get<StandartResponse<{ saved: DiscorveryItem[] }>>(
      `${BASE}/saved`,
      {
        type: filterType,
      }
    );
  }

  public toggleLike(id: string, type: string) {
    return this.http.post<
      StandartResponse<{
        status: number;
      }>
    >(`${BASE}/like`, {
      modelId: id,
      modelType: type,
    });
  }

  public toggleSaved(id: string, type: string) {
    return this.http.post<
      StandartResponse<{
        status: number;
      }>
    >(`${BASE}/save`, {
      modelId: id,
      modelType: type,
    });
  }

  public getInterests() {
    return this.http.get<
      StandartResponse<{
        main: DiscoveryCategory[];
        sectors: DiscoveryCategory[];
        other: {
          universities: DiscoveryCategory[];
          companies: DiscoveryCategory[];
          communities: DiscoveryCategory[];
          people: DiscoveryCategory[];
          projects: DiscoveryCategory[];
          events: DiscoveryCategory[];
          jobPostings: DiscoveryCategory[];
        };
      }>
    >(`${BASE}/interests`);
  }

  putInterests(selectedInterests: DiscoveryCategory[]) {
    return this.http.post(`${BASE}/interests`, {
      interests: selectedInterests,
    });
  }
}

export enum SAVED_FILTER_TYPE {
  ALL = 'all',
  POST = 'Post',
  EVENT = 'Event',
  JOB_POSTING = 'JobPosting',
  PROJECT = 'Project',
  USER = 'User',
  UNIVERSITY = 'University',
  COMMUNITY = 'Community',
  COMPANY = 'Company',
  SECTOR = 'Sector',
}
