import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'slider-item',
  templateUrl: './slider-item.component.html',
  styleUrls: ['./slider-item.component.scss'],
})
export class SliderItemComponent implements OnInit {
  @Input() public itemSize: number = 25;

  @HostBinding('style.flex') public get itemFlex() {
    return `0 0 calc(${this.itemSize}% - 32px)`;
  }

  constructor() {}

  ngOnInit(): void {}
}
