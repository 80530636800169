import { NavbarControllerService } from './../navbar-controller.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { OverlayPanel } from '@common/ui/overlay-panel/overlay-panel.service';
import { map } from 'rxjs';
import { UserMenuRefService } from '../user-menu-ref.service';

@Component({
  selector: 'menu-more',
  templateUrl: './menu-more.component.html',
  styleUrls: ['./menu-more.component.scss'],
})
export class MenuMoreComponent {
  constructor(
    private breakPointObserver: BreakpointObserver,
    private formBuilder: FormBuilder,
    private router: Router,
    private bottomSheet: MatBottomSheet,
    public userMenuRefService: UserMenuRefService
  ) {}

  public search() {
    this.router.navigate(['/search'], {
      queryParams: { query: this.searchForm.value.query },
    });
    if (this.isMobile) {
      const ref = this.bottomSheet._openedBottomSheetRef;
      if (ref) ref.dismiss();
    }
  }

  public isMobile = this.breakPointObserver
    .observe('(max-width: 800px)')
    .pipe(map((result) => result.matches));

  public searchForm = this.formBuilder.group({
    query: ['', Validators.min(3)],
  });
}
