import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'guest-navbar',
  templateUrl: './guest-navbar.component.html',
  styleUrls: ['./guest-navbar.component.scss'],
})
export class GuestNavbarComponent implements OnInit {
  @ViewChild(MatMenuTrigger) discUni: MatMenuTrigger;
  @ViewChild('forProfessionals') discPro: MatMenuTrigger;
  @Output() toggleMenu = new EventEmitter();
  menuIcon1 = 'expand_more';
  menuIcon2 = 'expand_more';
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {}

  changeIcon(menuData: string) {
    switch (menuData) {
      case 'menu1':
        this.discUni.menuClosed.subscribe(
          () => (this.menuIcon1 = 'expand_more')
        );
        this.discUni.menuOpened.subscribe(
          () => (this.menuIcon1 = 'expand_less')
        );
        break;
      case 'menu2':
        this.discPro.menuClosed.subscribe(
          () => (this.menuIcon2 = 'expand_more')
        );
        this.discPro.menuOpened.subscribe(
          () => (this.menuIcon2 = 'expand_less')
        );
        break;
    }
  }
}
