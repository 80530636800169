import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateMonthName'
})
export class DateMonthNamePipe implements PipeTransform {

  transform(value: Date | undefined | null, ...args: any[]): string {
    if (value) {
      const date = new Date(value);
      const month = date.getMonth();
      const months = ['Ocak','Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];
      return months[month];
    }
    return ""
  }

}
