import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TextEditorComponent} from './text-editor.component';
import {MatIconModule} from '@angular/material/icon';
import {TranslationsModule} from '@common/core/translations/translations.module';
import { TextEditorFormComponent } from './text-editor-form/text-editor-form.component';
import { TextEditor2Component } from './text-editor2/text-editor2.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslationsModule,
        // material
        MatButtonModule,
        MatSnackBarModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatDialogModule,
        MatMenuModule,
        MatIconModule,
    ],
    declarations: [
        TextEditorComponent,
        TextEditorFormComponent,
        TextEditor2Component,
    ],
    exports: [
        TextEditorComponent,
        TextEditorFormComponent,
        TextEditor2Component
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TextEditorModule { }
