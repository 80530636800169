import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { MatButton } from '@angular/material/button';

@Directive({
  selector: `button[mat-button][loading],
             button[mat-raised-button][loading],
             button[mat-icon-button][loading],
             button[mat-fab][loading],
             button[mat-mini-fab][loading],
             button[mat-stroked-button][loading],
             button[mat-flat-button][loading]`,
})
export class MatButtonLoadingDirective implements OnChanges {
  @Input() loading: boolean;

  constructor(private matButton: MatButton, private render: Renderer2) {}

  get nativeElement(): HTMLElement {
    return this.matButton._elementRef.nativeElement;
  }

  @HostBinding()
  @Input()
  get disabled() {
    return this.loading;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['loading']) {
      return;
    }

    if (changes['loading'].currentValue) {
      this.nativeElement.classList.add('mat-loading');
      this.matButton.disabled = true;
    } else if (!changes['loading'].firstChange)
      this.nativeElement.classList.remove('mat-loading');
    this.matButton.disabled = false;
  }
}
