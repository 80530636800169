<h1 mat-dialog-title>Bilet Oluştur</h1>
<div mat-dialog-content style="width: 100%">
  <form [formGroup]="generalGroup" (submit)="submit()">
    <ng-template matStepLabel>Genel Bilgiler</ng-template>
    <div>
      <h2>Biletin ismi ne olsun?</h2>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>İsim</mat-label>
        <input matInput placeholder="" formControlName="name" required />
      </mat-form-field>
    </div>
    <div>
      <h2>Bilete kısa açıklama girin</h2>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Kısa açıklama</mat-label>
        <input matInput placeholder="" formControlName="description" required />
      </mat-form-field>
    </div>
    <div>
      <h2>Biletiniz sınırlı mı?</h2>
      <mat-slide-toggle formControlName="hasMaxTickets">Evet</mat-slide-toggle>

      <mat-form-field *ngIf="generalGroup.value.hasMaxTickets" appearance="outline" style="width: 100%; margin-top:16px;">
        <mat-label>Katılımcı sınırı</mat-label>
        <input
          matInput
          type="number"
          placeholder=""
          formControlName="maxTickets"
          required
        />
      </mat-form-field>
    </div>

    <div>
      <h2>Biletin türü veya konumunu girin.</h2>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Biletin türü</mat-label>
        <input
          matInput
          placeholder="Standart koltuk"
          formControlName="location"
          required
        />
      </mat-form-field>
    </div>
    <div>
      <h2>Biletinizin bitiş tarihi var mı?</h2>
      <mat-slide-toggle formControlName="hasEndDate">Evet</mat-slide-toggle>
      <mat-form-field
        *ngIf="generalGroup.value.hasEndDate"
        appearance="outline"
        style="width: 100%; margin-top:16px;"
      >
        <mat-label>Bitiş Tarihi</mat-label>
        <input matInput formControlName="endDate" [matDatepicker]="endPicker" />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="endPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">İptal</button>
  <button
    mat-button
    cdkFocusInitial
    (click)="submit()"
    *ngIf="generalGroup.valid"
  >
    Oluştur
  </button>
</div>
