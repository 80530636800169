import { Component, Input } from '@angular/core';

@Component({
  selector: 'loading-chip',
  templateUrl: './loading-chip.component.html',
  styleUrls: ['./loading-chip.component.scss']
})
export class LoadingChipComponent {
  @Input('margin') public margin = '16px';
}
