<mat-toolbar color="primary">
  <div class="d-flex align-items-center justify-content-center mainLogo">
    <a class="logo-container" [routerLink]="['/home']">
      <img [src]="logo | async" class="logo" alt="UniLocked" />
    </a>
    <button class="menu-button" mat-icon-button (click)="admindrawer.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="menuList d-flex justify-content-between align-items-center">
    <div class="mainContent d-flex flex-column">
      <span class="title">{{ title }}</span>
      <span class="subtitle">{{ desc }}</span>
    </div>
    <div class="userContent d-flex">
      <div class="job-area d-flex align-items-center hover-effect" (click)="selectProfile()">
        <ng-container *ngIf="isSmallScreen">
          <mat-icon>account_box</mat-icon>
        </ng-container>
        <ng-container *ngIf="!isSmallScreen">
          <img
            class="job-image"
            [src]="auth.selectedProfile.avatar"
            alt="job-img"
          />
        </ng-container>
        <div class="jobInfo d-flex flex-column">
          <p class="name d-flex align-items-center">
            {{ auth.selectedProfile.display_name || auth.selectedProfile.name }}
            
          </p>
          <p class="type">{{ profileTypeName }}</p>
        </div>
      </div>
      <div class="user-area hover-effect">
        <div
          class="userCard d-flex align-items-center"
          [matMenuTriggerFor]="isSmallScreen ? menu2 : menu"
        >
          <ng-container *ngIf="isSmallScreen">
            <mat-icon>account_box</mat-icon>
          </ng-container>
          <ng-container *ngIf="!isSmallScreen">
            <img
              class="job-image"
              [src]="auth.selectedProfile.avatar"
              alt="job-img"
            />
          </ng-container>
          <div class="userInfo d-flex flex-column justify-content-center">
            <p class="name d-flex align-items-center">
              {{ auth.user.display_name || auth.user.name }}
              
            </p>
            <p class="type" *ngIf="auth.selectedMember?.memberRole">{{auth.selectedMember?.memberRole?.display_name || ""}}</p>
          </div>
        </div>

        <mat-menu #menu2="matMenu">
          <button mat-menu-item (click)="selectProfile()">Profil Seçin</button>
          <button mat-menu-item [matMenuTriggerFor]="menu">Diğer</button>
        </mat-menu>

        <mat-menu #menu="matMenu">
          <button
            [disabled]="auth.loading$ | async"
            (click)="logout()"
            mat-menu-item
          >
            <mat-icon>logout</mat-icon>
            <span>Çıkış Yap</span>
          </button>

          <button
            routerLink="/settings"
            [disabled]="auth.loading$ | async"
            mat-menu-item
          >
            <mat-icon>settings</mat-icon>
            <span>Seçenekler</span>
          </button>

          <button (click)="toggleDarkMode()" mat-menu-item>
            <mat-icon>image</mat-icon>
            <span>{{ theme.darkMode ? "Açık Tema" : "Koyu Tema" }}</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</mat-toolbar>
