import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class Toast {
  constructor(private _snackBar: MatSnackBar) {}

  public open(message: string) {
    this._snackBar.open(message, 'Kapat', {
      duration: 3000,
    });
  }

  public openWithMessage(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  public openWithConfig(message: string, action: string, config: MatSnackBarConfig) {
    this._snackBar.open(message, action, config);
  }
}
