import { Component, Input, OnInit } from '@angular/core';
import { DiscoveryCatalogService } from '@common/services/discovery-catalog.service';

@Component({
  selector: 'like-save-card',
  templateUrl: './like-save-card.component.html',
  styleUrls: ['./like-save-card.component.scss'],
})
export class LikeSaveCardComponent implements OnInit{
  @Input({ required: true }) data: any;


  liked: boolean = false;
  saved: boolean = false;

  constructor(private discoveryService: DiscoveryCatalogService) {}

  toggleLike() {
    this.discoveryService.toggleLike(this.data.id || this.data.id, this.data.type).subscribe(
      (response) => {
        console.log('Like toggled successfully', response);
        this.liked = response.data.status == 201;
      },
      (error) => {
        console.error('Error toggling like', error);
      }
    );

    this.liked = !this.liked;
  }

  toggleSave() {
    this.discoveryService.toggleSaved(this.data.id || this.data.id, this.data.type).subscribe(
      (response) => {
        // İsteğin başarılı bir şekilde tamamlandığında burada işlemler yapabilirsiniz
        console.log('Saved status toggled successfully', response);
        this.saved = response.data.status == 201;
      },
      (error) => {
        // İsteğin bir hata ile sonuçlanması durumunda burada işlemler yapabilirsiniz
        console.error('Error toggling saved status', error);
      }
    );

    this.saved = !this.saved;
  }


  ngOnInit(): void {
    this.saved = this.data?.userSaved > 0;
    this.liked = this.data?.userLikes > 0;
  }
}
