import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TextEditorImageValidator  {
    protected readonly DEFAULT_MAX_SIZE_MB = 3;

    protected initValidations() {
        /*const validations = [
            new FileSizeValidation(
                {maxSize: convertToBytes(this.DEFAULT_MAX_SIZE_MB, 'MB')},
                this.i18n
            ),
            new FileTypeValidation({types: ['image']}, this.i18n),
        ];

        this.validations.push(...validations);*/
    }
}
