<mat-card class="featured-content hover-effect-card" *ngIf="getMappedData$ | async as data" [routerLink]="getLink(data)">
  <div class="d-flex flex-column justify-content-between" style="min-height: 400px;">
    <div>
      <div class="header d-flex justify-content-between flex-sm-wrap">
        <div class="user d-flex">
          <media-image
            *ngIf="data.userImage"
            [src]="data.userImage"
            width="40px"
            height="40px"
            appereance="circle"
          ></media-image>
          <div class="name">
            <p>{{ data.userName }}</p>
            <p class="d-flex align-items-center" >
              <mat-icon class="d-flex justify-content-center align-items-center">open_in_new</mat-icon> <span>{{ getOpenText(data.type) }}</span>
            </p>
          </div>
        </div>
        <div class="type">
          {{ getTypeText(data.type) }}
        </div>
      </div>
      <div class="content">
        <p class="title" *ngIf="data.title">{{ data.title }}</p>
        <p class="text" [innerHTML]="data.text"></p>
        <media-image
          *ngIf="data.image"
          [src]="data.image"
          width="100%"
          height="auto"
          appereance="rounded"
        ></media-image>
        <video-player style="width: 100%;" *ngIf="data.video" [video]="data.video"></video-player>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button
        *ngIf="showButton"
        mat-flat-button
        color="primary"
        (click)="buttonClick.emit()"
      >
        <mat-icon>auto_awesome</mat-icon> {{ getButtonText(data.type) }}
      </button>
    </div>
  </div>
</mat-card>
