import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Pagination } from 'src/app/models/pagination';

@Injectable({
  providedIn: 'root',
})
export class PaginatorService {
  constructor() {}

  public datas = new Map<string, any[]>();
  public paginations = new Map<string, Pagination<any>>();
  public pages = new Map<string, number>();
  public callbacks = new Map<
    string,
    (page: number) => Observable<Pagination<any>>
  >();
  public lastPages = new Map<string, number>();
  public changed$ = new Map<string, ReplaySubject<any>>();
  public loadings = new Map<string, boolean>();

  public setDatas(key: string, data: any[]) {
    this.datas.set(key, data);
  }

  public getDatas(key: string) {
    return this.datas.get(key);
  }

  public setCallback(
    key: string,
    callback: (page: number) => Observable<Pagination<any>>
  ) {
    this.callbacks.set(key, callback);
  }

  public init(
    key: string,
    callback: (page: number) => Observable<Pagination<any>>
  ) {
    this.pages.set(key, 1);
    if (!this.changed$.get(key)) {
      this.changed$.set(key, new ReplaySubject());
    }
    this.setCallback(key, callback);
    this.goToPage(key, 1);
  }

  public goToPage(key: string, page: number) {
    if (this.callbacks.get(key)) {
      this.loadings.set(key, true);
      this.callbacks
        .get(key)(page)
        .subscribe((response) => {
          this.pages.set(key, response.current_page);
          this.lastPages.set(key, response.last_page);
          this.datas.set(key, response.data);
          this.paginations.set(key, response);
          if (this.changed$.get(key)) {
            this.changed$.get(key).next(response);
          } else {
            this.changed$.set(key, new ReplaySubject());
            this.changed$.get(key).next(response);
          }
          this.loadings.set(key, false);
        });
    }
  }



  public nextPage(key: string) {
    if (this.callbacks.get(key)) {
      const page = this.pages.get(key) || 1;
      this.goToPage(key, page + 1);
    }
  }
}
