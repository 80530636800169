import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { AppThemeService } from '@common/ui/theming/app-theme.service';
import { BehaviorSubject, finalize, map } from 'rxjs';
import { Members } from 'src/app/models/relations/members';
import { User } from 'src/app/models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedIn = false;
  public loading$ = new BehaviorSubject(false);
  public loadingStatus$ = new BehaviorSubject(false);
  public redirect: string | null = '/home';
  public user?: User;
  public user$ = new BehaviorSubject<User>(null);

  public followedIds = [];

  public error?: string;
  public _members: Members[] = [];
  public unreadNotifications: number = 0;

  public get members() {
    return this._members || this.user?.memberTo || [];
  }

  get selectedProfileId() {
    try {
      return this.user?.selectedMemberId || this.user.id;
    } catch (error) {
      return null;
    }
  }

  get selectedMember(): Members {
    if (this.selectedProfileId) {
      const foundMember = this.members.find((member) => {
        return (
          member.profileId == this.selectedProfileId &&
          member.profile !== undefined
        );
      });
      return foundMember
        ? {
            ...foundMember,
            profileType: foundMember.profile?.type || 'Organization',
          }
        : this.userAsMember;
    }

    return this.userAsMember;
  }

  get selectedProfile() {
    if (this.selectedMember) {
      return this.selectedMember.profile;
    } else {
      return this.user;
    }
  }

  get userAsMember(): Members {
    return {
      member: this.user,
      memberId: this.user.id,
      memberRole: null,
      profile: this.user,
      profileId: this.user.id,
      profileType: 'User',
    };
  }

  constructor(
    private router: Router,
    private http: AppHttpClient,
    private activatedRoute: ActivatedRoute,
    private injector: Injector,
    private appTheme: AppThemeService
  ) {}

  public init(url: string = null) {
    this.redirect = url;

    this.loading$.next(true);
    this.loadingStatus$.next(true);
  }

  public login(username: string, password: string) {
    this.loading$.next(true);
    this.http
      .post<UserResponse>('auth/login', {
        email: username,
        password: password,
      })
      .subscribe({
        next: (response) => {
          if (response.success) {
            this.http.setToken(response.token);
            this.handleLogin(response, true);
          } else {
            this.error = response.error;
          }
        },
        error: (error) => {
          this.error = error.error;
          this.loading$.next(false);
        },
        complete: () => {
          this.loading$.next(false);
        },
      });
  }

  public register(data: any) {
    this.loading$.next(true);
    return this.http
      .post<UserResponse>('auth/register', data)
      .pipe(finalize(() => this.loading$.next(false)));
  }

  public logout() {
    this.http.post('auth/logout', {}).subscribe(() => {
      this.loggedIn = false;
      this.user = null;
      this.user$.next(null);
      this.redirect = null;
      this.error = null;
      this.loading$.next(false);
      this.http.setToken(undefined);
      this.appTheme.loggedIn = false;
    });
  }

  public handleLogin(response: HandleLogin, redirect: boolean = true) {
    if (response.success) {
      this.user = response.user;
      this.user$.next(response.user);
      this.loggedIn = true;
    } else {
      this.error = response.error;
    }

    this.loading$.next(false);

    if (redirect) {
      this.router.navigate([this.redirect ? this.redirect : '/home']);
    }
  }

  public hasPermission(permission: string) {
    const permissions = [];
    if (this.user) {
      for (const role of this.user.roles || []) {
        permissions.push(...role.permissions);
      }
    }
    if (permissions.includes(permission) || permissions.includes('admin')) {
      return true;
    }
    return false;
  }

  public hasProfilePermission(permission: string) {
    return true;
  }

  public selectProfile(profileId: string) {
    try {
      localStorage.setItem('lastRoom', JSON.stringify(null));
    } catch (error) {
      //console.log(error);
    }
    this.http
      .post('auth/select-profile', { selectedMemberId: profileId })
      .subscribe(() => {
        location.reload();
      });
  }

  public listProfiles() {
    return this.http.get<StandartResponse<Members[]>>('auth/members-to-select');
  }

  public joinBeta(data: {
    email?: string;
    name?: string;
    source?: string;
    organization?: string;
  }) {
    return this.http.post<StandartResponse<{ message: string }>>(
      'invite-beta/register-to-waitlist',
      data
    );
  }
}

interface UserResponse {
  token: string;
  success: boolean;
  user?: User;
  error?: string;
  userId?: string;
  needEmailConfirmation?: boolean;
}

interface HandleLogin {
  success: boolean;
  user?: User;
  error?: string;
}
