import { Component, Input, OnInit } from '@angular/core';
import { File } from 'src/app/models/file';

@Component({
  selector: 'media-image',
  templateUrl: './media-image.component.html',
  styleUrls: ['./media-image.component.scss'],
})
export class MediaImageComponent implements OnInit {
  @Input() public lazy: boolean = true;
  @Input() public alt: string = '';
  @Input() public src: string | File;
  @Input() public width: string;
  @Input() public height: string ;
  @Input() public objectFit: string = 'contain';
  @Input() public appereance: 'circle' | 'default' | 'rounded' = 'rounded';
  @Input() public aspectRatio: string = 'none';
  @Input() public priority: boolean = false;
  @Input() public sizes: string;
  @Input() public fill = false;
  @Input() public zIndex: number = 0;
  @Input() styleHeight: string = 'auto';

  ngOnInit(): void {
    if (!this.fill && (!this.width || !this.height)) {
      throw new Error(
        'You must provide width and height attributes when fill is not set'
      );
    }
  }

  onImgError(event) {
    event.target.src = 'assets/img/default.png';
  }

  public isFile(): boolean {
    return !(typeof this.src === 'string');
  }

  public getSrcAsFile(): File {
    return this.src as File;
  }
}
