import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Translations } from '../translations/translations.service';
import { Injectable } from '@angular/core';
import { Toast } from '@common/core/toast/toast/toast.service';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export abstract class HttpErrorHandlerService {
  protected constructor(protected i18n: Translations, protected toast: Toast) {}

  public handle(
    response: HttpErrorResponse,
    uri?: string,
    options: { [key: string]: any } = {}
  ): Observable<never> {
    const errResponse =
      typeof response.error === 'object' && response.error !== null
        ? response.error
        : {};
    errResponse.status = response.status;
    errResponse.type = 'http';

    if (!errResponse.errors) {
      errResponse.errors = {};
    }

    Object.keys(errResponse.errors).forEach((key) => {
      const message = errResponse.errors[key];
      errResponse.errors[key] = Array.isArray(message) ? message[0] : message;
    });

    if (response.status == 422) {
      this.toast.open('Lütfen girdiğiniz değerleri kontrol ediniz.');
    }

    if (response.status == 403) {
      this.toast.open('Bu işlemi yapmaya yetkiniz yok.');
    }
    console.log(errResponse,uri);
    return throwError(() => errResponse);
  }

  public handleForm(response: any, formGroup: FormGroup) {
    if (response.errors && response.errors?.length) {
      for (let errors of response.errors || []) {
        Object.keys(errors).forEach((key) => {
          const control = formGroup?.get(key);
          if (control) {
            control.setErrors({ server: errors[key] });
            console.log(control);
          }
        });
      }
    }

    return throwError(() => response);
  }

  /**
   * Redirect user to login page or show toast informing
   * user that he does not have required permissions.
   */
  protected abstract handle403Error(response: any);
}
