<h1 mat-dialog-title>Dosya Yükle</h1>
<div mat-dialog-content>
  <mat-progress-bar mode="indeterminate" *ngIf="!((finishedUploads$ | async).length == files.length)"></mat-progress-bar>
  <div class="container">
    <ngx-file-drop
      dropZoneLabel="Drop files here"
      (onFileDrop)="dropped($event)"
    >
      <ng-template
        ngx-file-drop-content-tmp
        let-openFileSelector="openFileSelector"
      >
        <div class="drop-container">
          <div class="drop-text">Dosyaları buraya sürükleyin</div>
          <div class="drop-button">
            <button
              mat-flat-button
              color="accent"
              type="button"
              class="button-primary"
              (click)="openFileSelector()"
            >
              Ya da Dosya Seçin
            </button>
          </div>
        </div>
      </ng-template>
    </ngx-file-drop>
    <div class="file-list" *ngIf="finishedUploads$ | async as files">
      <div class="row">
        <div class="col-sm">
          <div class="file-item" *ngFor="let file of files">
            <div class="file-name">{{ file.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions *ngIf="finishedUploads$ | async as finishedUploads">
  <button mat-button [mat-dialog-close]="false">İptal</button>
  <button
    *ngIf="finishedUploads.length > 0"
    mat-button
    color="primary"
    [disabled]="!((finishedUploads).length == files.length)"
    [mat-dialog-close]="finishedUploads"
  >
    Kaydet
  </button>
</div>
