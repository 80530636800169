import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
})
export class TextInputComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  inputValue: string | number;
  touched = false;
  @Input() label: string;
  @Input() icon: string;
  @Input() iconTooltip: string;
  @Output() change = new EventEmitter<any>();

  @Input() isSubmitted: boolean;
  @Input() inputType: string = 'text';
  @Input() disable: boolean;
  @Input() invalidState: boolean;
  @Input() validState: boolean;
  @Input() errorDesc: string = 'REQUIRED_FIELD';
  @Input() placeholder: string = 'Alanı Doldurun';

  constructor() {}
  ngOnInit() {}

  valueChanged(val: string | number) {
    this.onChange(val);

    this.markAsTouched();
  }

  onChange = (value: string | number) => {};

  onTouched: any = () => {};

  ngOnChanges(changes: SimpleChanges): void {}

  writeValue(value: string | number): void {
    this.inputValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disable = isDisabled;
  }

  private markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched();
    }
  }
}
