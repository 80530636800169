import { FormGroup } from '@angular/forms';
import { Injectable, TransferState, makeStateKey } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { HttpErrorHandlerService } from '@common/core/http/http-error-handler.service';
import { AppHttpClient } from './http.service';
import { isPlatformServer } from '@angular/common';

export class AppHttpContext {
  protected httpClient: HttpClient;
  protected errorHandler: HttpErrorHandlerService;

  public formGroup: FormGroup;
  public token: BehaviorSubject<string | undefined>;
  private transferState: TransferState;
  private platformId: Object;

  public init(
    httpClient: HttpClient,
    errorHandler: HttpErrorHandlerService,
    transferState: TransferState,
    token: BehaviorSubject<string | undefined>,
    platformId: Object
  ) {
    this.httpClient = httpClient;
    this.errorHandler = errorHandler;
    this.transferState = transferState;
    this.token = token;
    this.platformId = platformId;
  }

  static prefixUri(uri: string) {
    if (
      uri.indexOf('://') > -1 ||
      uri.startsWith(AppHttpClient.prefix) ||
      uri.startsWith('api')
    ) {
      return uri;
    }
    return `${AppHttpClient.prefix}/${uri}`;
  }

  public buildAuthHeader() {
    if (!this.token.value) {
      console.error('No token provided');
      return new HttpHeaders();
    }
    console.log('Bearer ' + this.token.value);
    return new HttpHeaders({
      Authorization: `Bearer ${this.token.value}`,
    });
  }

  public get<T>(uri: string, params = {}, options: object = {}): Observable<T> {
    const stateKey = makeStateKey<T>('get_' + uri);
    const cachedData = this.transferState.get(stateKey, null);
    if (cachedData) {
      this.transferState.remove(stateKey);
      return new Observable((observer) => {
        observer.next(cachedData);
        observer.complete();
      });
    }

    const httpParams = this.transformQueryParams(params);
    (options as any).headers = this.buildAuthHeader();
    return this.httpClient
      .get<T>(AppHttpContext.prefixUri(uri), {
        params: httpParams,
        ...options,
      })
      .pipe(
        map((data) => {
          if (this.token.value && isPlatformServer(this.platformId)) {
            this.transferState.set(stateKey, data);
          }
          return data;
        })
      )
      .pipe(catchError((err) => this.errorHandler.handle(err, uri, options)));
  }

  public post<T>(uri: string, payload: object = null): Observable<T> {
    return this.httpClient
      .post<T>(AppHttpContext.prefixUri(uri), payload, {
        headers: this.buildAuthHeader(),
      })
      .pipe(catchError((err) => this.errorHandler.handle(err, uri)))
      .pipe(
        catchError((err) => this.errorHandler.handleForm(err, this.formGroup))
      );
  }

  public put<T>(uri: string, payload: object = {}): Observable<T> {
    payload = this.spoofHttpMethod(payload, 'PUT');
    return this.httpClient
      .put<T>(AppHttpContext.prefixUri(uri), payload, {
        headers: this.buildAuthHeader(),
      })
      .pipe(catchError((err) => this.errorHandler.handle(err, uri)))
      .pipe(
        catchError((err) => this.errorHandler.handleForm(err, this.formGroup))
      );
  }

  public delete<T>(uri: string, payload: object = {}): Observable<T> {
    const options = {
      headers: this.buildAuthHeader(),
      body: payload,
    };
    payload = this.spoofHttpMethod(payload, 'DELETE');
    return this.httpClient
      .delete<T>(AppHttpContext.prefixUri(uri), options)
      .pipe(catchError((err) => this.errorHandler.handle(err, uri)));
  }

  public postWithProgress(uri: string, params: FormData) {
    const req = new HttpRequest('POST', AppHttpContext.prefixUri(uri), params, {
      reportProgress: true,
      headers: this.buildAuthHeader(),
    });
    return this.httpClient.request(req).pipe(
      catchError((err) => this.errorHandler.handle(err, uri)),
      filter((e) =>
        [
          HttpEventType.Sent,
          HttpEventType.UploadProgress,
          HttpEventType.Response,
        ].includes(e.type)
      )
    );
  }

  public transformQueryParams(params: object | null) {
    let httpParams = new HttpParams();

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        httpParams = httpParams.append(key, value == null ? '' : value);
      });
    }

    return httpParams;
  }

  public spoofHttpMethod(
    params: object | FormData,
    method: 'PUT' | 'DELETE'
  ): object | FormData {
    if (params instanceof FormData) {
      (params as FormData).append('_method', method);
    } else {
      params['_method'] = method;
    }

    return params;
  }
}
