import {
  AfterViewInit,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'pick-permission',
  templateUrl: './pick-permission.component.html',
  styleUrls: ['./pick-permission.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PickPermissionComponent),
      multi: true,
    },
  ],
})
export class PickPermissionComponent
  implements ControlValueAccessor, OnInit, AfterViewInit
{
  @Input('target') target: 'Event' | 'All' | 'Profile' | 'Project' | 'None';
  @ViewChild('permissions') permissions: MatSelectionList;

  public profile = {
    name: 'Profiller',
    items: [
      {
        name: 'Profil Olarak Gezin',
        permission: 'profiles.control',
      },
      {
        name: 'Takip Etme',
        permission: 'follow',
      },
      {
        name: 'Profil Düzenleme',
        permission: 'profiles.update',
      },
      {
        name: 'Profil Görüntüleme',
        permission: 'profiles.view',
      },
      {
        name: 'Profili Sil',
        permission: 'profiles.delete',
      },
      {
        name: 'Etkinlik Oluşturma',
        permission: 'events.create',
      },
      {
        name: 'Etkinlik Düzenleme',
        permission: 'events.update',
      },
      {
        name: 'Etkinlik Silme',
        permission: 'events.delete',
      },
      {
        name: 'Etkinlik Görüntüleme',
        permission: 'events.view',
      },
      {
        name: 'Etkinlik Biletlerini Görüntüleme',
        permission: 'events.tickets.view',
      },
      {
        name: 'Etkinlik Biletlerini Düzenleme',
        permission: 'events.tickets.update',
      },
      {
        name: 'Etkinlik Biletlerini Silme',
        permission: 'events.tickets.delete',
      },
      {
        name: 'Etkinlik QR Oluşturma',
        permission: 'events.qenerate-qr',
      },
      {
        name: 'Proje Oluşturma',
        permission: 'projects.create',
      },
      {
        name: 'Proje Düzenleme',
        permission: 'projects.update',
      },
      {
        name: 'Proje Silme',
        permission: 'projects.delete',
      },
      {
        name: 'Proje Görüntüleme',
        permission: 'projects.view',
      },
      {
        name: 'İlanları Görüntüleme',
        permission: 'jobPosting.view',
      },
      {
        name: 'İlanları Düzenleme',
        permission: 'jobPosting.update',
      },
      {
        name: 'İlanları Sil',
        permission: 'jobPosting.delete',
      },
      {
        name: 'İlan Ekleme',
        permission: 'jobPosting.create',
      },
      {
        name: 'İlan Başvurularını Görüntüleme',
        permission: 'jobPosting.application.view',
      },
      {
        name: 'İlan Başvurularını Düzenleme',
        permission: 'jobPosting.application.update',
      },
      {
        name: 'İlan Başvurularını Silme',
        permission: 'jobPosting.application.delete',
      },
      {
        name: 'Rolleri Görüntüleme',
        permission: 'roles.view',
      },
      {
        name: 'Rolleri Düzenleme',
        permission: 'roles.update',
      },
      {
        name: 'Rolleri Silme',
        permission: 'roles.delete',
      },
      {
        name: 'Kullanıcıları Görüntüleme',
        permission: 'members.view',
      },
      {
        name: 'Kullanıcıları Düzenleme',
        permission: 'members.update',
      },
      {
        name: 'Kullanıcıları Silme',
        permission: 'members.delete',
      },
      {
        name: 'Kullanıcı Ekleme',
        permission: 'members.add',
      },
    ],
  };

  public projects = {
    name: 'Projeler',
    items: [
      {
        name: 'Proje Düzenleme',
        permission: 'projects.update',
      },
      {
        name: 'Proje Silme',
        permission: 'projects.delete',
      },
      {
        name: 'Proje Görüntüleme',
        permission: 'projects.view',
      },
    ],
  };

  public events = {
    name: 'Etkinlikler',
    items: [
      {
        name: 'Etkinlik Düzenleme',
        permission: 'events.update',
      },
      {
        name: 'Etkinlik Silme',
        permission: 'events.delete',
      },
      {
        name: 'Etkinlik Görüntüleme',
        permission: 'events.view',
      },
      {
        name: 'Etkinlik QR Oluşturma',
        permission: 'events.qenerate-qr',
      },
      {
        name: 'Etkinlik Biletlerini Görüntüleme',
        permission: 'events.tickets.view',
      },
      {
        name: 'Etkinlik Biletlerini Düzenleme',
        permission: 'events.tickets.update',
      },
      {
        name: 'Etkinlik Biletlerini Silme',
        permission: 'events.tickets.delete',
      },
    ],
  };

  public categories = [];

  ngOnInit() {
    if (this.target === 'Profile') {
      this.categories = [this.profile];
    } else if (this.target === 'Project') {
      this.categories = [this.projects];
    } else if (this.target === 'Event') {
      this.categories = [this.events];
    } else if (this.target === 'All') {
      this.categories = [this.profile, this.projects, this.events];
    } else {
      this.categories = [];
    }
  }

  public selectedPermissions: string[] = [];

  ngAfterViewInit(): void {
    this.permissions.selectionChange.subscribe(() => {
      this.onChange();
    });
  }

  writeValue(obj: any): void {
    this.selectedPermissions = obj ? obj : [];
    if (this.permissions) {
      console.log('write', obj);
      this.permissions.selectedOptions.clear();
      this.permissions.options.forEach((option) => {
        if (obj?.includes(option?.value)) {
          this.permissions.selectedOptions.select(option);
        }
      });
    }
  }

  propagateChange = (_: any) => {};

  public onChange() {
    this.propagateChange(
      this.permissions.selectedOptions.selected
        .map((option) => option.value)
        .filter((item) => item)
    );
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  public toggleAll() {
    if (this.permissions.selectedOptions.selected.length === 1) {
      this.permissions.selectAll();
    } else {
      this.permissions.deselectAll();
    }
    this.onChange();
  }
}
