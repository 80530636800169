// button.service.ts

import { Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationStart, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ButtonService implements OnInit {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.subscribe(data => {
      if (data instanceof ActivationStart) {
        const data1 = data.snapshot.data;


        if (data1['title']) {
          this.setButtonName(data1['title']);
        }
      }
    });
  }

  private buttonName: string = '';

  setButtonName(name: string): void {
    this.buttonName = name;
  }

  getButtonName(): string {
    return this.buttonName;
  }

  ngOnInit(): void {}
}
