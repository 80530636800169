import { OverlayRef } from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { UserMenuRefService } from '../user-menu-ref.service';
import { AppThemeService } from '@common/ui/theming/app-theme.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SelectProfileDialogComponent } from '@common/auth/select-profile-dialog/select-profile-dialog.component';

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent {
  constructor(
    public authService: AuthService,
    public userMenuRef: UserMenuRefService,
    public theme:AppThemeService,
    public bottomsheet:MatBottomSheet
  ) {}

  public avatar = this.authService.user?.avatar;
  public firstName = this.authService.user?.first_name;
  public lastName = this.authService.user?.last_name;

  get darkMode() {
    return this.theme.darkMode;
  }

  get darkModeIcon() {
    return this.darkMode ? 'light_mode' : 'dark_mode';
  }

  public toggleDarkMode() {
    this.theme.darkMode = !this.darkMode;
    this.userMenuRef.ref?.close();
  }

  get darkModeText() {
    return this.darkMode ? 'Açık Mod' : 'Karanlık Mod';
  }

  public selectProfile() {
    this.bottomsheet.open(SelectProfileDialogComponent);
    this.userMenuRef.ref?.close();
  }

  public logout() {
    this.authService.logout();
    this.userMenuRef.ref?.close();
  }
}
