import { Component } from '@angular/core';

@Component({
  selector: 'center-loading',
  templateUrl: './center-loading.component.html',
  styleUrls: ['./center-loading.component.scss']
})
export class CenterLoadingComponent {

}
