<user-navbar [drawer]="drawer"></user-navbar>
<mat-drawer-container class="example-container">
  <mat-drawer
    class="mainMenu"
    #drawer
  
    style="width: 250px; min-height: 90vh"
    [mode]="(isMobile | async) ? 'over' : 'side'"
    [opened]="(isOpen | async)"
  >
    <main-menu></main-menu>
  </mat-drawer>
  <mat-drawer-content [class.paddingLeftSsr]="isNotBrowser">
    <ng-content></ng-content>
  </mat-drawer-content>
</mat-drawer-container>
