import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { EventsService } from '@common/services/events.service';
import { Event } from 'src/app/models/event';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';


@Component({
  selector: 'apply-event-dialog',
  templateUrl: './apply-event-dialog.component.html',
  styleUrls: ['./apply-event-dialog.component.scss']
})
export class ApplyEventDialogComponent {

  constructor(
    public ref: MatBottomSheetRef<ApplyEventDialogComponent>,
    public fb: FormBuilder,
    public events:EventsService,
    public router:Router,
    public auth:AuthService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {event: Event}
  ) {}



}
