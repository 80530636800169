import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { JobPosting } from 'src/app/models/job-posting';
import { Pagination } from 'src/app/models/pagination';
import { Profile } from 'src/app/models/profile';
import { JobApplication } from 'src/app/models/relations/jobs/job-application';

const BASE = 'job-posting';

@Injectable({
  providedIn: 'root',
})
export class JobPostingService {
  constructor(private http: AppHttpClient) {}

  public indexPosts() {
    return this.http.get<StandartResponse<Pagination<JobPosting>>>(BASE);
  }

  public indexPostsOfProfile(companyId: string, page: number = 1) {
    return this.http.get<StandartResponse<Pagination<JobPosting>>>(
      `${BASE}/profile/${companyId}`,
      {
        page,
      }
    );
  }

  public changeStatus(id: string, status: string) {
    return this.http.put<StandartResponse<JobPosting>>(`${BASE}/${id}/status`, {
      status,
    });
  }

  public getHome() {
    return this.http.get<
      StandartResponse<{
        suggestedTags: string[];
        suggestedJobPostings: JobPosting[];
        newJobPostings: JobPosting[];
        profiles: Profile[];
      }>
    >(BASE + '/home');
  }

  public searchPosts(
    find: {
      title: string;
    },
    locations: string[],
    tags: string[]
  ) {
    return this.http.post<
      StandartResponse<{
        hits: JobPosting[];
      }>
    >(BASE + '/search', {
      find,
      locations,
      tags,
      sort: {
        field: 'created_at',
        order: 'desc',
      },
    });
  }

  public getJobPosting(id: string) {
    return this.http.get<StandartResponse<JobPosting>>(`${BASE}/${id}`);
  }

  public createPost(data: any) {
    return this.http.post<StandartResponse<JobPosting>>(BASE, data);
  }

  public updatePost(postId: string, data: any, form: FormGroup) {
    return this.http.withForm(form).put<StandartResponse<JobPosting>>(
      `${BASE}/${postId}`,
      data
    );
  }

  public deletePost(id: string) {
    return this.http.delete<StandartResponse<JobPosting>>(`${BASE}/${id}`);
  }

  public apply(
    id: string,
    data: {
      form: Partial<{
        first_name: string;
        last_name: string;
        gender: string;
        id_number: string;
        country: string;
        city: string;
        email: string;
        phone: string;
      }>;
      answers: any;
      jobPosting: string;
    }
  ) {
    return this.http.post<StandartResponse<any>>(
      `${BASE}/${id}/applications`,
      data
    );
  }

  public viewApplications(id: string, page) {
    return this.http.get<StandartResponse<Pagination<JobApplication>>>(
      `${BASE}/${id}/applications`,
      {
        page,
      }
    );
  }

  public deleteApplication(applicationId: string) {
    return this.http.delete<StandartResponse<JobApplication>>(
      `${BASE}/applications/${applicationId}`
    );
  }

  public changeApplicationStatus(applicationId: string, status: string) {
    return this.http.put<StandartResponse<JobApplication>>(
      `${BASE}/applications/${applicationId}/status`,
      {
        status,
      }
    );
  }
}
