import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrentRouteService {
  constructor(private activatedRoute: ActivatedRoute) {}

 

  public isActive(route: string) {
    let currentRoute = this.activatedRoute;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
   
    return currentRoute.routeConfig?.path === route;
  }
}
