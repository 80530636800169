<div class="content" [class.withTheme]="withTheme">
  <h2 class="title">Etkinlik Biletleri</h2>
  <p class="info-text">
    Etkinliğe katılmak için bilet satın almanız gerekmektedir. Paranız Unilocked
    ile güvence altındadır.
  </p>
  <div class="application" *ngFor="let application of event.applications">
    <div class="row">
      <div class="col-8">
        <h4 class="name">{{ application.name }}</h4>
        <p class="info">{{ application.description }}</p>
      </div>
      <div class="col-4 d-flex justify-content-end">
        <div>
          <h3 class="price">
            {{ priceText(application) }}
          </h3>
          <p class="limit-warning" *ngIf="hasLimitWarning(application)">
            Son {{ application.maxTickets - application.ticketCount }} bilet
          </p>
          <button mat-flat-button *ngIf="!soldOut(application)" (click)="openPurchasePage(application)" >Bilet Alın</button>
          <button mat-flat-button *ngIf="soldOut(application)" [disabled]="true">Tükendi</button>
        </div>
      </div>
    </div>
  </div>
</div>
