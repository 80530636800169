import { UserMenuRefService } from './user-menu-ref.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { ButtonService } from '@common/services/button.service';
import { OverlayPanel } from '@common/ui/overlay-panel/overlay-panel.service';
import { AppThemeService } from '@common/ui/theming/app-theme.service';
import { Observable, map, mapTo } from 'rxjs';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { BOTTOM_POSITION } from '@common/ui/overlay-panel/positions/bottom-position';
import { MenuMoreComponent } from './menu-more/menu-more.component';
import { MatDialog } from '@angular/material/dialog';
import { AddEventDialogComponent } from '@common/ui/add-event-dialog/add-event-dialog.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NavbarControllerService } from './navbar-controller.service';

@Component({
  selector: 'user-navbar',
  templateUrl: './user-navbar.component.html',
  styleUrls: ['./user-navbar.component.scss'],
})
export class UserNavbarComponent implements OnInit {
  @Input('drawer') public drawer;

  @ViewChild('avatar') public avatar: ElementRef<HTMLButtonElement>;
  @ViewChild('more', {
    static: true,
  })
  public more: ElementRef<HTMLButtonElement>;

  constructor(
    public auth: AuthService,
    public theme: AppThemeService,
    public formBuilder: FormBuilder,
    public router: Router,
    private breakPointObserver: BreakpointObserver,
    public buttonService: ButtonService,
    public overlay: OverlayPanel,
    public userMenuRefService: UserMenuRefService,
    public dialog: MatDialog,
    public bottomSheet: MatBottomSheet,
    public navbarController: NavbarControllerService
  ) {}

  ngOnInit(): void {
    this.navbarController.onClose = () => {};
  }

  public isMobileValue = false;
  public logo = new Observable<string>((observer) => {
    this.isMobile.subscribe((isMobile) => {
      this.isMobileValue = isMobile;
      if (isMobile) {
        observer.next('assets/img/logo-mobile.png');
      } else {
        this.theme.changed$.subscribe((darkMode) => {
          observer.next(
            darkMode
              ? 'assets/img/logo-mobile.png'
              : 'assets/img/logo-mobile.png'
          );
        });
      }
    });
  });

  public searchForm = this.formBuilder.group({
    query: ['', Validators.min(3)],
  });

  public isMobile = this.breakPointObserver
    .observe('(max-width: 800px)')
    .pipe(map((result) => result.matches));

  public logout() {
    this.auth.logout();
  }

  public openCreatePost() {
    this.dialog.open(AddEventDialogComponent);
  }

  public toggleDarkMode() {
    this.theme.darkMode = !this.theme.darkMode;
  }

  public search() {
    this.router.navigate(['/search'], {
      queryParams: { query: this.searchForm.value.query },
    });
  }

  public openUserMenu() {
    this.userMenuRefService.ref = this.overlay.open(UserMenuComponent, {
      origin: this.avatar,
      position: BOTTOM_POSITION,
      hasBackdrop: true,
      backdropClass: 'user-menu-backdrop',
      panelClass: 'user-menu-panel',
      fullScreen: false,
      closeOnBackdropClick: true,
      maxWidth: 500,
      data: {
        user: this.auth.user,
      },
      scrollStrategy: 'close',
    });
  }

  public openMoreMenu() {
    if (this.isMobileValue) {
      this.userMenuRefService.ref = this.bottomSheet.open(MenuMoreComponent, {
        data: {
          user: this.auth.user,
        },
      });
    } else {
      this.userMenuRefService.ref = this.overlay.open(MenuMoreComponent, {
        origin: this.avatar,
        position: BOTTOM_POSITION,
        hasBackdrop: true,
        backdropClass: 'user-menu-backdrop',
        panelClass: 'user-menu-panel',
        fullScreen: false,
        closeOnBackdropClick: true,
        maxWidth: 500,
        data: {
          user: this.auth.user,
        },
        scrollStrategy: 'close',
      });
    }
  }
}
