import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateDayMonth',
})
export class DateDayMonthPipe implements PipeTransform {
  transform(value: Date | null | undefined, ...args: any[]): string {
    if (value) {
      const date = new Date(value);
      const day = date.getDate();
      const localizedMonth = new Intl.DateTimeFormat('tr', {
        month: 'long',
      }).format(date);
      return `${day} ${localizedMonth}`;
    }
    return '';
  }
}
