import { Component } from '@angular/core';

@Component({
  selector: 'main-text-card',
  templateUrl: './main-text-card.component.html',
  styleUrls: ['./main-text-card.component.scss'],
})
export class MainTextCardComponent {
  datas = [
    {
      type: 'positive',
      text: 'Rastgele önerilenler',
    },
    {
      type: 'positive',
      text: "Unilocked'da Yeni",
    },
  ];

  public rating = Math.floor(Math.random() * 5) + 3;
}
