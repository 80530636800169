import { ImagesData } from './../../app/models/file';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { TinymceTextEditor } from './editors/tinymce-text-editor.service';
import { TextEditorImageValidator } from './validation/text-editor-image-validator';
import { ucFirst } from '../core/utils/uc-first';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { SettingsService } from '@common/core/settings/settings.service';
import { AuthService } from '@common/auth/auth.service';
import { BOTTOM_POSITION } from '@common/ui/overlay-panel/positions/bottom-position';
import { OverlayPanel } from '@common/ui/overlay-panel/overlay-panel.service';
import { UploadService } from '@common/core/upload/upload.service';

export interface LinkAttrs {
  text: string;
  href: string;
  target?: string;
}

@Component({
  selector: 'text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TinymceTextEditor],
})
export class TextEditorComponent implements OnDestroy, AfterViewInit {
  @ViewChild('visualArea', { static: true }) visualTextArea: ElementRef;
  @ViewChild('sourceArea', { static: true }) sourceTextArea: ElementRef;

  public sourceAreaControl = new FormControl();
  public activeEditor = 'visual';

  @Input() showAdvancedControls = false;
  @Input() basic = false;
  @Input() minHeight: number | string = 183;
  @Input() maxHeight = 530;
  @Input() inlineUploadPrefix: string;
  @Input() set content(value: string) {
    this.editor.setContents(value);
  }

  @Output() onChange: EventEmitter<string> = new EventEmitter();
  @Output() onCtrlEnter = new EventEmitter();
  @Output() onFileUpload = new EventEmitter<any[]>();

  constructor(
    public editor: TinymceTextEditor,
    public currentUser: AuthService,
    //private uploadQueue: UploadQueueService,
    private settings: SettingsService,
    private renderer: Renderer2,
    public el: ElementRef,
    private overlayPanel: OverlayPanel,
    private imageValidator: TextEditorImageValidator,
    public uploads: UploadService
  ) {}

  private timeout: any;
  ngAfterViewInit() {
    var interval = (this.timeout = setInterval(() => {
      if (this.editor.waitForEditor()) {
        clearInterval(interval);
        this.bootTextEditor();
      }
    }, 1000));
  }

  ngOnDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.destroyEditor();
  }

  public reset() {
    this.editor.reset();
  }

  public focus() {
    this.editor.focus();
  }

  public hasUndo(): boolean {
    return this.editor.hasUndo();
  }

  public hasRedo(): boolean {
    return this.editor.hasRedo();
  }

  /**
   * Queries the current state for specified text editor command.
   * For example if the current selection is "bold".
   */
  public queryCommandState(name: string): boolean | number {
    return this.editor.queryCommandState(name);
  }

  public execCommand(name: string, value: string | number = null) {
    this.editor.execCommand(name, value);
  }

  /**
   * Insert information container of specified type into the editor.
   */
  public insertInfoContainer(type: string) {
    // TODO: refactor into shortcodes maybe if need more of similar buttons in the future
    // TODO: translate once angular translation service is available
    this.insertContents(
      `<div class="widget widget-${type}"><div class="title">${ucFirst(
        type
      )}:</div><br></div><br>`
    );
  }

  public async showColorPicker(
    event: any,
    command: string,
    origin: HTMLElement
  ) {
    // this.execCommand(command, color);
    console.log(event);
    const fakeInput = document.createElement('input');
    fakeInput.type = 'color';
    fakeInput.style.position = 'fixed';
    fakeInput.style.top = '0';
    fakeInput.style.left = '0';
    fakeInput.style.marginTop = '-1000px';
    document.body.appendChild(fakeInput);
    fakeInput.click();
    fakeInput.onchange = () => {
      this.execCommand(command, fakeInput.value);
      document.body.removeChild(fakeInput);
    };
  }

  public showVisualEditor() {
    if (
      !this.editor.tinymceInstance.contentAreaContainer ||
      this.activeEditor === 'visual'
    )
      return;

    this.activeEditor = 'visual';

    this.renderer.setStyle(
      this.editor.tinymceInstance.contentAreaContainer,
      'display',
      'block'
    );
    this.renderer.setStyle(
      this.sourceTextArea.nativeElement,
      'display',
      'none'
    );

    this.editor.focus();
  }

  public showSourceEditor() {
    if (
      !this.editor.tinymceInstance.contentAreaContainer ||
      this.activeEditor === 'source'
    )
      return;

    this.activeEditor = 'source';

    this.renderer.setStyle(
      this.sourceTextArea.nativeElement,
      'height',
      this.editor.tinymceInstance.contentAreaContainer.offsetHeight + 'px'
    );
    this.renderer.setStyle(
      this.sourceTextArea.nativeElement,
      'display',
      'block'
    );
    this.renderer.setStyle(
      this.editor.tinymceInstance.contentAreaContainer,
      'display',
      'none'
    );

    this.sourceAreaControl.setValue(
      this.editor.getContents({ source_view: true })
    );
  }

  public openFileUploadDialog() {
    /*openUploadWindow({multiple: true}).then(fileList => {
            this.onFileUpload.emit(fileList);
        });*/
  }

  public openInsertImageModal() {
    this.uploads.openUploadDialog('image', false, (files) => {
      this.onFileUpload.emit(files);
      this.insertImage(files[0].imagesData.variants[0]);
    });

    /*const params: UploadApiConfig = {
            uri: UploadUri.Image,
            validator: this.imageValidator,
            httpParams: {diskPrefix: this.inlineUploadPrefix}
        };
        openUploadWindow({types: [UploadInputTypes.image]}).then(files => {
            this.uploadQueue.start(files, params).subscribe(response => {
                if (!response.fileEntry.url.startsWith("http")) {
                    response.fileEntry.url = window.location.protocol + "//" + window.location.hostname  + "/" + response.fileEntry.url;
                }
                
                this.insertImage(response.fileEntry.url);
            });
        });*/
  }

  public getContents(): string {
    return this.editor.getContents();
  }

  public setContents(contents: string) {
    this.editor.setContents(contents);
  }

  public insertContents(contents) {
    this.editor.insertContents(contents);
  }

  public insertImage(url: string) {
    this.editor.insertImage(url);
  }

  public insertLink(attrs: LinkAttrs) {
    const target = attrs.target || 'self';
    this.insertContents(
      `<a href="${attrs.href}" target="${target}">${attrs.text}</a>`
    );
  }

  public destroyEditor() {
    this.editor.destroyEditor();
  }

  private bootTextEditor() {
    this.editor.setConfig({
      textAreaEl: this.visualTextArea,
      editorEl: this.el,
      minHeight: this.minHeight,
      maxHeight: this.maxHeight,
      onChange: this.onChange,
      onCtrlEnter: this.onCtrlEnter,
      showAdvancedControls: this.showAdvancedControls,
    });
  }
}
