import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { SelectProfileDialogComponent } from '@common/auth/select-profile-dialog/select-profile-dialog.component';
import { AppThemeService } from '@common/ui/theming/app-theme.service';
import { Observable, filter, map, switchMap } from 'rxjs';

@Component({
  selector: 'admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.scss'],
})
export class AdminNavbarComponent implements OnInit {
  @Input('admindrawer') public admindrawer;
  isSmallScreen: boolean = false;
  title: string = 'Ana Sayfa';
  desc: string = 'Şirket profiliniz ile ilgili detayları görüntüleyin';
  constructor(
    public auth: AuthService,
    public theme: AppThemeService,
    public formBuilder: FormBuilder,
    public router: Router,
    private breakPointObserver: BreakpointObserver,
    public bottomSheet: MatBottomSheet
  ) {
    this.breakPointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe((result) => {
        this.isSmallScreen = result.matches;
      });
  }

  ngOnInit(): void {
    this.router.events.subscribe({
      next: (response: any) => {
        switch (response?.routerEvent?.url) {
          case '/admin':
            this.title = 'Ana Sayfa';
            this.desc = 'Şirket profiliniz ile ilgili detayları görüntüleyin';
            break;
          case '/admin/profile':
            this.title = 'Profil';
            this.desc = 'Şirket profiliniz ile ilgili detayları görüntüleyin';
            break;
          case '/admin/content-publishing-hub/list':
            this.title = 'Gönderiler';
            this.desc = 'Şirket gönderilerinizi burada görüntüleyebilirsiniz.';
            break;
          case '/admin/project-center':
            this.title = 'Projeler';
            this.desc =
              'Şirketinize ait projeleri burada görüntüleyebilirsiniz.';
            break;
          case '/admin/event-center/week':
            this.title = 'Etkinlikler';
            this.desc =
              'Şirketinize ait etkinlikleri burada görüntüleyebilirsiniz.';
            break;
          case '/admin/job-posting':
            this.title = 'İlanlar';
            this.desc =
              'Şirketinize ait ilanları burada görüntüleyebilirsiniz.';
            break;
          case '/admin/blog-center':
            this.title = 'Blog';
            this.desc =
              'Şirketinize ait blog yazılarını burada görüntüleyebilirsiniz.';
            break;
          case '/admin/people':
            this.title = 'Kişiler';
            this.desc =
              'Hesabınızı takip edenleri, çalışanlarınızı ve rollerini yönetin.';
            break;

          default:
            this.title = 'Ana Sayfa';
            this.desc = 'Şirket profiliniz ile ilgili detayları görüntüleyin';
            break;
        }
      },
    });
  }

  public logo = new Observable<string>((observer) => {
    this.theme.changed$
      .pipe(switchMap((theme) => this.isMobile))
      .subscribe((isMobile) => {
        if (isMobile) {
          observer.next('assets/img/logo-mobile.png');
        } else {
          observer.next(
            this.theme.darkMode
              ? 'assets/img/logo-white.png'
              : 'assets/img/logo.png'
          );
        }
      });
  });

  public searchForm = this.formBuilder.group({
    query: ['', Validators.min(3)],
  });

  public isMobile = this.breakPointObserver
    .observe('(max-width: 800px)')
    .pipe(map((result) => result.matches));

  public logout() {
    this.auth.logout();
  }

  public toggleDarkMode() {
    this.theme.darkMode = !this.theme.darkMode;
  }

  public search() {
    this.router.navigate(['/search'], {
      queryParams: { query: this.searchForm.value.query },
    });
  }

  public get profileTypeName() {
    return this.auth.selectedMember.profileType == 'User'
      ? 'Kullanıcı Profili'
      : 'Şirket Profili';
  }

  public selectProfile() {
    this.bottomSheet.open(SelectProfileDialogComponent);
  }
}
