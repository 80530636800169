<h1 mat-dialog-title>Rol Oluştur</h1>
<div mat-dialog-content style="width: 100%">
  <mat-stepper orientation="horizontal" [linear]="true" #stepper>
    <mat-step [stepControl]="generalGroup">
      <form [formGroup]="generalGroup">
        <ng-template matStepLabel>İsim</ng-template>

        <div>
          <h2>Rolünüzün ismi ne olsun?</h2>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>İsim</mat-label>
            <input matInput placeholder="" formControlName="name" required />
          </mat-form-field>
        </div>
        <div>
          <h2>Role bir kısa açıklama girin.</h2>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Kısa açıklama</mat-label>
            <input
              matInput
              placeholder=""
              formControlName="description"
              required
            />
          </mat-form-field>
        </div>
        <div class="d-flex flex-column mt-2">
          <div class="d-flex flex-column spacing">
            <span><b>Rol rengi</b></span>
            <span style="width: fit-content"
              >Etkinlik kartı ve sayfasında görünecek renk paletini seçin.</span
            >
          </div>
          <div class="flexWrap" aria-label="Select an option">
            <div
              (click)="generalGroup.controls['color'].setValue(color.color)"
              *ngFor="let color of colors"
              class="d-flex"
            >
              <input
                type="radio"
                id="color"
                [value]="color.color"
                formControlName="color"
              />
              <div
                [style.backgroundColor]="color.color"
                class="colorCube"
              ></div>
            </div>
          </div>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="permissionGroup" [completed]="members?.length > 0">
      <form [formGroup]="permissionGroup">
        <ng-template matStepLabel>İzinler</ng-template>
        <div>
          <h2>Rolün izinlerini seçin.</h2>
          <pick-permission
            formControlName="permissions"
            [target]="upperFirstLetter(generalGroup.get('type').value)"
          ></pick-permission>
        </div>
      </form>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Kişiler</ng-template>
      <div *ngIf="members.length <= 0" class="">
        <span>Üye Bulunamadı</span>
      </div>
      <div *ngIf="members.length > 0" class="d-flex flex-column">
        <mat-card
          *ngFor="let member of members"
          class="d-flex justify-content-between"
        >
          <mat-card-header class="d-flex flex-row align-items-center">
            <img [src]="member.profile.avatar" />
            <span
              ><b>{{
                member.profile.first_name + " " + member.profile.last_name
              }}</b></span
            >
            <mat-icon color="primary" *ngIf="member.profile.id"
              >verified</mat-icon
            >
          </mat-card-header>
          <mat-card-actions>
            <button (click)="deleteMemberOnRole(member)" mat-button>
              <mat-icon>delete</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </mat-step>
  </mat-stepper>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">İptal</button>
  <button mat-button (click)="stepper.previous()">Önceki</button>
  <button
    mat-button
    cdkFocusInitial
    (click)="stepper.next()"
    *ngIf="currentIndex == 0"
  >
    Sonraki
  </button>
  <button
    mat-button
    cdkFocusInitial
    [disabled]="loading"
    (click)="submit()"
    *ngIf="canSubmit() && currentIndex == 1"
  >
    Kaydet
  </button>
</div>
