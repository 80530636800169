import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  PLATFORM_ID,
} from '@angular/core';
import { File } from 'src/app/models/file';

declare var dashjs: any;

@Component({
  selector: 'video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements AfterViewInit {
  @Input() public video: File;
  @Input() public lazy: boolean = true;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    
  }

  public player: Plyr;

  public get videoElement(): HTMLVideoElement | null {
    return document.querySelector('video');
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // window or document'a erişim güvenli
      //import Plyr from 'plyr';

      import('plyr').then((Plyr) => {
        this.lazyInitDash(Plyr);
      });
    }
  }

  public lazyInitDash(Plyr?: any) {
    const url = 'https://cdn.dashjs.org/latest/dash.all.min.js';
    const script = document.createElement('script');
    script.src = url;
    script.onload = () => {
      this.initDash(Plyr);
    };
    document.head.appendChild(script);
  }

  public initDash(Plyr:any) {
    if (!this.video?.streamData) {
      return;
    }
    console.log('dashjs', Plyr);
    const player = dashjs.MediaPlayer().create();
    player.initialize(
      document.querySelector('#vidd'),
      this.video.streamData.playback.dash,
      true
    );
    this.player = new Plyr.default('#vidd', {
      disableContextMenu: true,
      muted: true,
      i18n: {
        quality: 'Kalite',
        speed: 'Hız',
      },
      keyboard: {
        focused: true,
        global: true,
      },
    });
  }

  public onIntersection({
    target,
    visible,
  }: {
    target: Element;
    visible: boolean;
  }): void {
    if (visible && this.lazy) {
      this.videoElement?.play().catch((e) => {});
    } else {
      this.videoElement?.pause();
    }
  }
}
