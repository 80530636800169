import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateLocale'
})
export class DateLocalPipe implements PipeTransform {

  transform(value: Date | string | null | undefined, ...args: unknown[]): string {
    if (!value) {
      return '';
    }
    const date = new Date(value);
    return date.toLocaleDateString();
  }

}
