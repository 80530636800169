<div class="slider">
  <button mat-icon-button (click)="scrollPrev()" *ngIf="controls">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <div class="slider-content" #slideContent>
    <ng-content></ng-content>
  </div>
  <button mat-icon-button (click)="scrollNext()" *ngIf="controls">
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>
