import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { map } from 'rxjs';
import { Pagination } from 'src/app/models/pagination';
import { Post } from 'src/app/models/post';
import { Profile } from 'src/app/models/profile';

const BASE = 'home';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(private http: AppHttpClient) {}

  public getSummary() {
    return this.http.get<
      StandartResponse<{
        date: Date;
        items: {
          id: string;
          title: string;
          name: string;
          details: string;
          image: string;
        }[];
      }>
    >(`${BASE}/summary`);
  }

  public getPosts(page:number) {
    return this.http
      .get<StandartResponse<Pagination<Post>>>('home', {
        page: page,
      })
      .pipe(map((res) => res.data));
  }

  public submitPost(data: Post) {
    return this.http.post<Post>('posts', data);
  }
  
  public youMightKnow() {
    return this.http.get<
      StandartResponse<{
        items: Profile[];
      }>
    >(`${BASE}/you-might-know`);
  }
}
