import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateDayName'
})
export class DateDayNamePipe implements PipeTransform {

  transform(value: Date | undefined | null, ...args: any[]): string {
    if (value) {
      const date = new Date(value);
      const day = date.getDay();
      const days = ['Pazar','Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'];
      return days[day];
    }
    return ""
  }


}
