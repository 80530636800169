  <img
  [class.circle]="appereance == 'circle'"
  [class.default]="appereance == 'default'"
  [class.rounded]="appereance == 'rounded'"
  [ngSrc]="isFile() ? (getSrcAsFile() | imageFile) : src"
  [width]="width"
  [height]="height"
  [sizes]="sizes"
  [priority]="priority"
  [fill]="fill"
  lazy
  [alt]="alt"
  [style]="{ aspectRatio:aspectRatio, objectFit: objectFit, position: 'relative', height: styleHeight, zIndex: zIndex }"
  (error)="onImgError($event)"
/>