import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateMonthYearNumber'
})
export class DateMonthYearNumberPipe implements PipeTransform {

  transform(value: Date | null | undefined, ...args: any[]): string {
    if (value) {
      const date = new Date(value);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      let str = `${year}`;
      // add 0 to month
      if (month < 10) {
        str = `0${month}.${str}`;
      } else {
        str = `${month}.${str}`;
      }
      return str;
    }
    return '';
  }

}
