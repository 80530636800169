import { Component, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { UploadService } from '@common/core/upload/upload.service';
import { JobPostingService } from '@common/services/job-posting.service';
import { ProjectsService } from '@common/services/projects.service';
import { AddProjectDialogComponent } from '../add-project-dialog/add-project-dialog.component';

@Component({
  selector: 'add-job-posting',
  templateUrl: './add-job-posting.component.html',
  styleUrls: ['./add-job-posting.component.scss'],
})
export class AddJobPostingComponent {
  constructor(
    public ref: MatDialogRef<AddJobPostingComponent>,
    public fb: FormBuilder,
    public uploads: UploadService,
    public projects: JobPostingService,
    public router: Router,
    public auth: AuthService
  ) {}

  @ViewChild('stepper') public stepper;
  @ViewChild('editor') public editor;

  public generalGroup = this.fb.group({
    title: ['', Validators.required],
  });

  public tagGroup = this.fb.group({
    tags: this.fb.array([]),
  });

  public get tags() {
    return this.tagGroup.get('tags') as FormArray;
  }

  public contentGroup = this.fb.group({
    description: ['', Validators.required],
  });

  public timeGroup = this.fb.group({
    startDate: ['', Validators.required],
  });

  public canSubmit() {
    return (
      this.generalGroup.valid && this.contentGroup.valid && this.timeGroup.valid
    );
  }

  public submit() {
    this.stepper.next();
    const data: any = {
      ...this.generalGroup.value,
      ...this.tagGroup.value,
      ...this.contentGroup.value,
      ...this.timeGroup.value,
    };
    data.ownerId = this.auth.selectedMember.profileId;
    data.ownerType = this.auth.selectedMember.profileType;
    this.projects.createPost(data as any).subscribe((data) => {
      this.ref.close();

      this.router.navigate(['/admin/job-posting/', data.data.id]);
    });
  }
}
