<h1 mat-dialog-title>Etkinlik Oluştur</h1>
<div mat-dialog-content style="width: 100%">
  <mat-stepper orientation="horizontal" [linear]="true" #stepper>
    <mat-step [stepControl]="generalGroup">
      <form [formGroup]="generalGroup">
        <ng-template matStepLabel>Genel Bilgiler</ng-template>
        <div>
          <h2>Etkinliğinizin ismi ne olsun?</h2>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>İsim</mat-label>
            <input matInput placeholder="" formControlName="name" required />
          </mat-form-field>
        </div>
        <div>
          <h2>Bir kısa açıklama girin</h2>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Kısa açıklama</mat-label>
            <input matInput placeholder="" formControlName="headnote" required />
          </mat-form-field>
        </div>
        <div>
          <h2>Etkinliğiniz nerede olacak?</h2>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Konum</mat-label>
            <input
              matInput
              placeholder=""
              formControlName="location"
              required
            />
          </mat-form-field>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="generalGroup">
      <form [formGroup]="generalGroup">
        <ng-template matStepLabel>Etiketler</ng-template>
        <div>
          <h2>Etkinliğinizi tanımlayan etkiketler girin.</h2>
          <tag-input style="width: 100%;" [tags]="tags"></tag-input>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="imageGroup">
      <form [formGroup]="imageGroup">
        <ng-template matStepLabel>Kapak Resmi</ng-template>
        <div>
          <h2>Etkinliğinize bir kapak fotoğrafı seçelim.</h2>
          <p>Resminizi büyük ve yatay seçmeye çalışın.</p>
          <div class="image-area">
            <img
              *ngIf="imageGroup.value.imageUrl"
              [src]="imageGroup.value.imageUrl"
              alt=""
            />
          </div>
          <div>
            <button mat-flat-button color="accent" (click)="pickImage()">
              Bir resim seçin.
            </button>
          </div>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="generalGroup">
      <form [formGroup]="generalGroup">
        <ng-template matStepLabel>İçerik</ng-template>
        <div>
          <h2>Etkinliğinize güzel bir açıklama girelim.</h2>

          <text-editor
            #editor
            (onChange)="contentChange()"
            [showAdvancedControls]="false"
          ></text-editor>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="timeGroup">
      <form [formGroup]="timeGroup">
        <ng-template matStepLabel>Tarihler</ng-template>
        <div>
          <h2>Etkinliğinizin başlangıç ve bitiş tarihlerini girin.</h2>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Başlangıç Tarihi</mat-label>
            <input
              matInput
              formControlName="startDate"
              [matDatepicker]="startPicker"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="startPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Başlangıç Saati</mat-label>

            <input
              matInput
              type="time"
              placeholder=""
              formControlName="startTime"
              required
            />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Bitiş Tarihi</mat-label>
            <input
              matInput
              formControlName="endDate"
              [matDatepicker]="endPicker"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="endPicker"
            ></mat-datepicker-toggle>

            <mat-datepicker #endPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Bitiş Saati</mat-label>
            <input
              matInput
              type="time"
              placeholder=""
              formControlName="endTime"
              required
            />
          </mat-form-field>
        </div>
      </form>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Mutlu son</ng-template>
      <h1>Neredeyse bitti.</h1>
      <p>Kalan kısımları hallediyoruz...</p>
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
    </mat-step>
  </mat-stepper>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">İptal</button>
  <button mat-button (click)="stepper.previous()">Önceki</button>
  <button
    mat-button
    cdkFocusInitial
    (click)="stepper.next()"
    *ngIf="!canSubmit()"
  >
    Sonraki
  </button>
  <button mat-button cdkFocusInitial (click)="submit()" *ngIf="canSubmit()">
    Oluştur
  </button>
</div>
