import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

export const loggedIn: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const authService = inject(AuthService);
    const router = inject(Router);
  return new Observable<boolean>((observer) => {
    
    authService.loading$.subscribe({
      next: (loading) => {
        if (!loading) {
          if (authService.loggedIn) {
            observer.next(true);
          } else {
            router.navigateByUrl('/login');
            observer.next(false);
          }
          observer.complete();
        }
      },
    });
  });
};
