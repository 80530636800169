import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { Pagination } from 'src/app/models/pagination';
import { Project } from 'src/app/models/project';
import { ProjectApplication } from 'src/app/models/relations/projects/project-application';
import { ProjectMission } from 'src/app/models/relations/projects/project-mission';

const BASE = 'projects';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  constructor(private http: AppHttpClient) {}

  getProjectsHome() {
    return this.http.get<StandartResponse<ProjectsData>>(BASE + '/');
  }

  getProjectsFromCommunities() {
    return this.http.get<StandartResponse<ProjectsData>>(
      BASE + '/from-communities'
    );
  }

  getProjectsFromFriends() {
    return this.http.get<StandartResponse<ProjectsData>>(
      BASE + '/from-friends'
    );
  }

  getMyProjects() {
    return this.http.get<StandartResponse<MyProjectsData>>(
      BASE + '/my-projects'
    );
  }

  create(project: Project) {
    return this.http.post<StandartResponse<Project>>(BASE, project);
  }

  public addApplication(projectId: string) {
    return this.http.post<StandartResponse<ProjectApplication>>(
      BASE + '/' + projectId + '/add-application',
      {}
    );
  }

  public get(id: string) {
    return this.http.get<StandartResponse<Project>>(BASE + '/' + id);
  }

  public update(
    id: string,
    data: {
      project: any;
      selectedProfileType: string;
      selectedProfileId: string;
    }
  ) {
    return this.http.put<StandartResponse<Project>>(BASE + '/' + id, data);
  }

  removeParticipant(project: string, user: string) {
    return this.http.delete<StandartResponse<Project>>(
      BASE + '/' + project + '/participants/' + user
    );
  }

  public getApplications(id: string, page: number) {
    return this.http.get<StandartResponse<Pagination<ProjectApplication>>>(
      BASE + '/' + id + '/applications'
    );
  }

  public getMissions(id: string, page: number) {
    return this.http.get<StandartResponse<Pagination<ProjectMission>>>(
      BASE + '/' + id + '/missions'
    );
  }

  public getMissionsOfUser(id: string, userId: string, page: number) {
    return this.http.get<StandartResponse<Pagination<ProjectMission>>>(
      BASE + '/' + id + '/missions/' + userId
    );
  }

  public getActivityOfUser(id: string, userId: string) {
    // /:id/user-activity/:userId
    return this.http.get<StandartResponse<any>>(
      BASE + '/' + id + '/user-activity/' + userId
    );
  }

  public getActivityByMonth(id: string, startDate: string) {
    return this.http.post<StandartResponse<any>>(
      BASE + '/' + id + '/analytics',
      {
        startDate: startDate,
      }
    );
  }

  public createMission(projectId: string, data: any) {
    return this.http.post<StandartResponse<ProjectMission>>(
      BASE + '/' + projectId + '/missions',
      data
    );
  }

  public updateMission(projectId: string, missionId: string, data: any) {
    return this.http.put<StandartResponse<ProjectMission>>(
      BASE + '/' + projectId + '/missions/' + missionId,
      data
    );
  }

  public deleteMission(projectId: string, missionId: string) {
    return this.http.delete<StandartResponse<ProjectMission>>(
      BASE + '/' + projectId + '/missions/' + missionId
    );
  }

  public allProjects(profileId: string, page: number) {
    return this.http.get<StandartResponse<Pagination<Project>>>(
      BASE + '/all-projects/' + profileId,
      {
        page: page,
        perPage: 20,
      }
    );
  }

  public getCommonPoints(projectId: string) {
    return this.http.get<StandartResponse<{ text: string; avatar: string }[]>>(
      BASE + '/' + projectId + '/common-points'
    );
  }

  delete(project: string) {
    return this.http.delete<StandartResponse<Project>>(BASE + '/' + project);
  }

  public updateApplicantStatus(
    projectId: string,
    applicationId: string,
    data: any
  ) {
    return this.http.put<StandartResponse<any>>(
      BASE +
        '/' +
        projectId +
        '/applications/' +
        applicationId +
        '/change-status',
      data
    );
  }

  changeStatus(projectId: string, status: string) {
    return this.http.put<StandartResponse<Project>>(
      BASE + '/' + projectId + '/change-status',
      { status: status }
    );
  }
}

export interface ProjectsData {
  joined: Project[];
  suggestions: Project[];
}

export interface MyProjectsData {
  active: Project[];
  past: Project[];
  invited: Project[];
}
