<h1 mat-dialog-title>Resmi Kırp</h1>
<div mat-dialog-content>
  <div class="container">
    <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="data?.aspectRatio || 1"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded($event)"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
    [containWithinAspectRatio]="true"
></image-cropper>
  </div>
</div>
<div matDialogActions>
    <button mat-button [mat-dialog-close]="false">İptal</button>
    <button mat-button color="primary" [mat-dialog-close]="croppedImage">Kaydet</button>
</div>
