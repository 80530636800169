import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateDayNumber'
})
export class DateDayNumberPipe implements PipeTransform {

  transform(value: Date | null | undefined, ...args: any[]): string {
    if (value) {
      const date = new Date(value);
      const day = date.getDate();
      return `${day}`;
    }
    return '';
  }

}
