<h1 mat-dialog-title>Etkinlik Ara</h1>
<div mat-dialog-content>
  <form [formGroup]="searchForm">
    <text-input label="Etkinlik Ara" placeholder="Etkinlik adı girin" formControlName="query"></text-input>
  </form>
  <div *ngIf="!loading && events" class="row">
    @for (item of events; track $index) {
        <div class="select-dialog col-lg-4 col-md-6 col-sm-12" (click)="selectEvent($event,item)">
            <div class="select-button" [class.selected]="item.id == selectedEvent?.id"></div>
            <event-card [event]="item" [disableClick]="true"></event-card>
        </div>
    }
  </div>
</div>
<div mat-dialog-actions>
  <button type="button" mat-dialog-close="" mat-button>İptal</button>
  <button
    type="button"
    mat-flat-button
    color="primary"
    [disabled]="!selectedEvent"
    cdkFocusInitial
    (click)="select()"
  >
    Seç
  </button>
</div>
