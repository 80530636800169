import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';

const BASE = 'poll';

@Injectable({
  providedIn: 'root',
})
export class PollService {
  constructor(private http: AppHttpClient) {}

  public vote(pollId: string, options: string[]) {
    return this.http.post(BASE + '/' + pollId + '/vote', { options });
  }

  public hasVoted(pollId: string) {
    return this.http.get<StandartResponse<any>>(BASE + '/' + pollId + '/voted');
  }
}
