import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { map } from 'rxjs';

@Component({
  selector: 'main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss'],
})
export class MainContentComponent implements OnInit {
  constructor(
    private breakPointObserver: BreakpointObserver,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit(): void {}

  public isNotBrowser = isPlatformServer(this.platformId);

  public isMobile = this.breakPointObserver.observe('(max-width: 800px)').pipe(
    map((result) => {
      console.log(result);
      return result.matches;
    })
  );

  public isOpen = this.isMobile.pipe(
    map((result) => {
      return !result && !this.isNotBrowser;
    })
  );
}
