<button
  *ngIf="following$ | async; else follow"
  mat-flat-button
  (click)="toggle($event)"
  class="button-other"
    [disabled]="loading$ | async"
>
  Takip Ediliyor
</button>
<ng-template #follow>
  <button mat-flat-button class="button-primary" (click)="toggle($event)" [disabled]="loading$ | async">Takip Et</button>
</ng-template>
