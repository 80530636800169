import { Injectable } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { BehaviorSubject, map } from 'rxjs';
import { Pagination } from 'src/app/models/pagination';
import { Profile } from 'src/app/models/profile';
import { User } from 'src/app/models/user';

const BASE = 'followers';

@Injectable({
  providedIn: 'root',
})
export class FollowersService {
  constructor(
    private http: AppHttpClient,
    private auth: AuthService,
    private currentUser: AuthService
  ) {}

  public getFollowing() {
    return this.http.get<StandartResponse<User[]>>(BASE + '/following');
  }

  public getMyFollowers() {
    return this.http.get<StandartResponse<User[]>>(BASE + '/my-followers');
  }

  public getConnections() {
    return this.http.get<StandartResponse<User[]>>(BASE + '/connections');
  }

  public getFollowers(profileId: string, page = 1) {
    return this.http.get<
      StandartResponse<{
        pagination: Pagination<Profile>;
      }>
    >('followers/' + profileId, { profileId, page });
  }

  public getAvaibleChatUsers(query: string = '') {
    return this.http
      .post<StandartResponse<Profile[]>>('followers/avaible-chat-users', {
        profileId: this.auth.selectedProfile.id,
        profileType: this.auth.selectedProfile.type,
        query,
      })
      .pipe(map((response) => response.data));
  }

  public toggle(
    profileId: string,
    profileType: string,
    followerId: string,
    followerType: string
  ) {
    return this.http.post('followers/toggle', {
      profileId,
      profileType,
      followerId,
      followerType,
    });
  }

  public toogleExtended(user, type: string = 'User') {
    if (
      this.currentUser.loggedIn &&
      user.id !== this.currentUser.selectedProfile.id
    ) {
      user.currentUserFollowed = !user.currentUserFollowed;

      return this.toggle(
        user.id,
        type,
        this.currentUser.selectedProfile.id,
        this.currentUser.selectedProfile.type
      ).pipe(
        map((response: any) => {
          user.currentUserFollowed = response.data.followed;
          return response;
        })
      );
    }
    return new BehaviorSubject(null);
  }
}
