import { Component } from '@angular/core';
import { HomeService } from '@common/services/home.service';
import { map } from 'rxjs/internal/operators/map';


@Component({
  selector: 'summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent {
  public summary$ = this.homeService.getSummary().pipe(map((res) => res.data));
  constructor(public homeService: HomeService) {
    
  }
}
