import { Component, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { Toast } from '@common/core/toast/toast/toast.service';
import { UploadService } from '@common/core/upload/upload.service';
import { ProfileService } from '@common/services/profile.service';
import { ProjectsService } from '@common/services/projects.service';
import { AddProjectDialogComponent } from '../add-project-dialog/add-project-dialog.component';

@Component({
  selector: 'add-profile-dialog',
  templateUrl: './add-profile-dialog.component.html',
  styleUrls: ['./add-profile-dialog.component.scss'],
})
export class AddProfileDialogComponent {
  constructor(
    public ref: MatDialogRef<AddProjectDialogComponent>,
    public fb: FormBuilder,
    public uploads: UploadService,
    public projects: ProjectsService,
    public router: Router,
    public auth: AuthService,
    public profiles: ProfileService,
    public toast: Toast
  ) {}

  @ViewChild('stepper') public stepper;
  @ViewChild('editor') public editor;

  public generalGroup = this.fb.group({
    type: ['', Validators.required],
    name: ['', Validators.required],
    headnote: ['', Validators.required],
  });

  public tagGroup = this.fb.group({
    tags: this.fb.array([]),
  });

  public get tags() {
    return this.tagGroup.get('tags') as FormArray;
  }

  public imageGroup = this.fb.group({
    avatarFile: [null, Validators.required],
    backdropFile: [null, Validators.required],
    imageUrl: [''],
    backdropUrl: [''],
  });

  public contentGroup = this.fb.group({
    about: ['', Validators.required],
  });

  public pickImage() {
    this.uploads
      .openUploadDialog$('image', false, {
        aspectRatio: 1,
      })
      .subscribe((files) => {
        this.imageGroup.patchValue({ avatarFile: files[0] });
        this.imageGroup
          .get('imageUrl')
          .setValue(files[0].imagesData.variants[0]);
      });
  }

  public pickBackdropImage() {
    this.uploads
      .openUploadDialog$('image', false, {
        aspectRatio: 16 / 9,
      })
      .subscribe((files) => {
        this.imageGroup.patchValue({ backdropFile: files[0] });
        this.imageGroup
          .get('backdropUrl')
          .setValue(files[0].imagesData.variants[0]);
      });
  }

  public canSubmit() {
    return (
      this.generalGroup.valid &&
      this.imageGroup.valid &&
      this.contentGroup.valid
    );
  }

  public submit() {
    this.stepper.next();
    const data: any = {
      ...this.generalGroup.value,
      ...this.tagGroup.value,
      ...this.imageGroup.value,
      ...this.contentGroup.value,
    };
    data.ownerId = this.auth.selectedMember.profileId;
    data.ownerType = this.auth.selectedMember.profileType;
    this.profiles.create(data as any).subscribe((data) => {
      this.ref.close();
      this.toast.open(
        'Profilinizi oluşturduk. Sağ menüden yeni profilinize geçebilirsiniz.'
      );
    });
  }
}
