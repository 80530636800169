<admin-navbar
  [admindrawer]="admindrawer"
  *ngIf="authService.loggedIn && isAdmin; else user"
></admin-navbar>
<ng-template #user>
  <user-navbar [drawer]="drawer" *ngIf="authService.loggedIn else guestNavbar"></user-navbar>
  <ng-template #guestNavbar>
    <guest-navbar></guest-navbar>
  </ng-template>
</ng-template>
