<ng-container *ngIf="summary$ | async as summary; else loading">
  <h2>
    <span class="text-primary">
      {{ summary.date | dateDayMonth }}
    </span>
    Özeti
  </h2>
  <mat-card
    appearance="outlined"
    *ngFor="let card of summary.items"
    [routerLink]="['/job-posting', card.id]"
    class="home-panel mat-elevation-z0 hover-effect-card"
  >
    <div class="right-panel">
      <div class="d-flex">
        <img class="profile-image" [src]="card.image" alt="" />
        <div style="width: 100%">
          <div class="top d-flex flex-column align-items-start">
            <h4 class="description">{{ card.title }}</h4>
            <h5 class="name">{{ card.name }}</h5>
          </div>
          <p class="card-info">{{ card.details }}</p>
        </div>
      </div>
    </div>
  </mat-card>
</ng-container>
<ng-template #loading>
  <div class="loading">
    <ngx-skeleton-loader
      style="width: 100%"
      [theme]="{ height: '30px', width: '50%' }"
    ></ngx-skeleton-loader>
    <mat-card style="padding: 10px; margin-top: 10px">
      <div class="d-flex" style="gap: 10px; width: 100%">
        <ngx-skeleton-loader
          [theme]="{ height: '50px', width: '50px' }"
          appearance="circle"
        ></ngx-skeleton-loader>
        <div class="d-flex flex-column" style="width: 100%">
          <ngx-skeleton-loader
            style="width: 100%"
            [theme]="{ height: '20px', width: '100%' }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            style="width: 100%"
            [theme]="{ height: '20px', width: '100%' }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            style="width: 100%"
            [theme]="{ height: '30px', width: '100%' }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </mat-card>
  </div>
</ng-template>
