import { Pipe, PipeTransform } from '@angular/core';
import { File } from 'src/app/models/file';

@Pipe({
  name: 'imageFile',
})
export class ImageFilePipe implements PipeTransform {
  transform(value: File | null | undefined, ...args: string[]): unknown {
    return imageFile(value, ...args);
  }
}

export function imageFile(file: File | null | undefined, ...args: string[]) {
  if (!file?.imagesData?.variants || !file?.imagesData?.variants.length) {
    if (args.length > 1) {
      return args[1];
    }
    return '/assets/img/default.png';
  }
  if (args.length && args[0] === 'thumbnail') {
    return file.imagesData.variants.find((variant) =>
      variant.includes('thumbnail')
    ) || file.imagesData.variants[0];
  }
  return file.imagesData.variants[0];
}
