import { Component, OnInit } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { CurrentRouteService } from '@common/core/router/current-route.service';
import { ButtonService } from '@common/services/button.service';
import { FeedbackOpenerServiceService } from '@common/services/feedback-opener-service.service';

@Component({
  selector: 'main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
  constructor(
    public auth: AuthService,
    public buttonService: ButtonService,
    public currentRoute: CurrentRouteService,
    private feedOpenerService: FeedbackOpenerServiceService
  ) {}

  ngOnInit(): void {}

  onButtonClick(): void {
    const buttonName = 'button';
    this.buttonService.setButtonName(buttonName);
  }

  openBottomFeedBackSheet() {
    this.feedOpenerService.open()
  }
}
