<div class="profile-actions">
  <div class="d-flex">
    <div>
      <div class="action-item" *ngFor="let data of datas">
        <div class="action-icon green" [ngSwitch]="data.type">
          <mat-icon *ngSwitchCase="'positive'">check</mat-icon>
        </div>
        <div class="action-text">{{ data.text }}</div>
      </div>
    </div>
    <div class="profile-rating">
      <div class="rating-badge">
        <div class="rating">{{ rating }}</div>
        <div class="rating-max">/10</div>
      </div>
    </div>
  </div>
</div>
