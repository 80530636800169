<h1 mat-dialog-title>Profil Oluştur</h1>
<div mat-dialog-content style="width: 100%">
  <mat-stepper orientation="horizontal" [linear]="true" #stepper>
    <mat-step [stepControl]="generalGroup">
      <form [formGroup]="generalGroup">
        <ng-template matStepLabel>Genel Bilgiler</ng-template>
        <div>
          <h2>Profilinizin türü nedir?</h2>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Tür</mat-label>
            <mat-select formControlName="type" required>
              <mat-option value="Company">Şirket</mat-option>
              <mat-option value="Community">Topluluk</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <h2>Profilinizin ismi ne olsun?</h2>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>İsim</mat-label>
            <input matInput placeholder="" formControlName="name" required />
          </mat-form-field>
        </div>
        <div>
          <h2>Bir kısa açıklama girin. Bunu slogan gibi düşünün.</h2>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Kısa açıklama</mat-label>
            <input
              matInput
              placeholder=""
              formControlName="headnote"
              required
            />
          </mat-form-field>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="generalGroup">
      <form [formGroup]="generalGroup">
        <ng-template matStepLabel>Etiketler</ng-template>
        <div>
          <h2>
            Profilinizi tanımlayan etkiketler girin. Bolca girmeyi unutmayın.
          </h2>
          <tag-input style="width: 100%" [tags]="tags"></tag-input>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="imageGroup">
      <form [formGroup]="imageGroup">
        <ng-template matStepLabel>Avatar Resmi</ng-template>
        <div>
          <h2>Profilinize bir profil fotoğrafı seçelim.</h2>
          <p>Resminizi kare seçmeye çalışın.</p>
          <div class="image-area">
            <img
              *ngIf="imageGroup.value.imageUrl"
              [src]="imageGroup.value.imageUrl"
              alt=""
            />
          </div>
          <div>
            <button mat-flat-button color="accent" (click)="pickImage()">
              Bir resim seçin.
            </button>
          </div>
        </div>
        <div>
          <h2>Şimdi de arka plan fotoğrafı seçelim.</h2>
          <p>Resminizi büyük ve yatay seçmeye çalışın.</p>
          <div class="image-area">
            <img
              *ngIf="imageGroup.value.backdropUrl"
              [src]="imageGroup.value.backdropUrl"
              alt=""
            />
          </div>
          <div>
            <button
              mat-flat-button
              color="accent"
              (click)="pickBackdropImage()"
            >
              Bir resim seçin.
            </button>
          </div>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="contentGroup">
      <form [formGroup]="contentGroup">
        <ng-template matStepLabel>İçerik</ng-template>
        <div>
          <h2>
            Profilinize güzel bir açıklama girelim. Bu açıklamayı uzun
            tutabilirsiniz.
          </h2>

          <text-editor-form #editor formControlName="about"></text-editor-form>
        </div>
      </form>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Mutlu son</ng-template>
      <h1>Neredeyse bitti.</h1>
      <p>Kalan kısımları hallediyoruz...</p>
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
    </mat-step>
  </mat-stepper>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">İptal</button>
  <button mat-button (click)="stepper.previous()">Önceki</button>
  <button
    mat-button
    cdkFocusInitial
    (click)="stepper.next()"
    *ngIf="!canSubmit()"
  >
    Sonraki
  </button>
  <button mat-button cdkFocusInitial (click)="submit()" *ngIf="canSubmit()">
    Oluştur
  </button>
</div>
