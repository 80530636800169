<h2>Profil Seç</h2>
<div *ngIf="members$ | async as members">
  <mat-action-list>
    <button
      mat-list-item
      *ngFor="let member of members"
      (click)="selectProfile(member)"
    >
      <img matListItemAvatar [src]="member.profile.avatar" alt="Avatar" />
      <h3 matListItemTitle>
        {{ member.profile.display_name || member.profile.name }}
      </h3>
      <p matListItemLine></p>
    </button>
    <button mat-list-item (click)="addProfile()">
      <h3 matListItemTitle>Yeni oluştur</h3>
      <p matListItemLine>Yeni şirket veya topluluk profili oluşturun.</p>
    </button>
  </mat-action-list>
</div>
