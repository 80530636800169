import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { ObjectId } from '@common/core/utils/object-id';
import { Observable } from 'rxjs';
import { Pagination } from 'src/app/models/pagination';
import { Profile } from 'src/app/models/profile';
import { Members } from 'src/app/models/relations/members';

const BASE = 'members';

@Injectable({
  providedIn: 'root',
})
export class MembersService {
  constructor(private http: AppHttpClient) {}

  public getMembers(page: number) {
    return this.http.get<
      StandartResponse<{
        pagination: Pagination<Profile>;
      }>
    >(BASE, {
      page,
    });
  }

  public addMember(data: any) {
    return this.http.post<StandartResponse<any>>(BASE + '/add-member', data);
  }
  deleteMember(memberId: ObjectId): Observable<StandartResponse<any>> {
    return this.http.delete(`${BASE}/${{ id: memberId }}`);
  }

  changeRole(memberId: any, roleId: any) {
    return this.http.post<StandartResponse<Members>>(`${BASE}/${memberId}/change-role`, { role: roleId });
  }
}
