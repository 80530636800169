import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { AddProfileDialogComponent } from '@common/ui/add-profile-dialog/add-profile-dialog.component';
import { map } from 'rxjs';
import { AuthService } from '../auth.service';

@Component({
  selector: 'select-profile-dialog',
  templateUrl: './select-profile-dialog.component.html',
  styleUrls: ['./select-profile-dialog.component.scss'],
})
export class SelectProfileDialogComponent {
  constructor(
    public auth: AuthService,
    public ref: MatBottomSheetRef<SelectProfileDialogComponent>,
    public dialog:MatDialog
  ) {}

  public members$ = this.auth
    .listProfiles()
    .pipe(map((response) => response.data))
    .pipe(
      map((members) => {
        return [this.auth.userAsMember, ...members];
      })
    );

  public selectProfile(member) {
    this.auth.selectProfile(member.profileId);
    this.ref.dismiss();
  }

  public addProfile() {
    this.ref.dismiss();
    this.dialog.open(AddProfileDialogComponent);
  }
}
