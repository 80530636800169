import { FollowersService } from '@common/services/followers.service';
import { Component, Input, OnInit } from '@angular/core';
import { Profile } from 'src/app/models/profile';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '@common/auth/auth.service';

@Component({
  selector: 'follow-button',
  templateUrl: './follow-button.component.html',
  styleUrls: ['./follow-button.component.scss'],
})
export class FollowButtonComponent implements OnInit {
  @Input() profile: any;
  @Input() type: string = 'User';

  constructor(
    public followersService: FollowersService,
    public auth: AuthService
  ) {}

  public following$ = new BehaviorSubject<boolean>(false);
  public loading$ = new BehaviorSubject<boolean>(false);

  ngOnInit() {
    console.log(this.auth.followedIds, this.profile.id);
    this.following$.next(this.auth.followedIds.includes(this.profile.id));
  }

  public toggle(event) {
    event.stopPropagation();
    this.following$.next(!this.following$.value);
    this.loading$.next(true);
    this.followersService
      .toogleExtended(this.profile, this.type)
      .subscribe((res: any) => {
        console.log(res);
        this.loading$.next(false);
      });
  }
}
