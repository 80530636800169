<navbar [admindrawer]="drawer"></navbar>
<mat-drawer-container class="example-container">
  <mat-drawer
    class="mainMenu"
    #drawer
    style="width: 300px"
    [mode]="(isMobile | async) ? 'over' : 'side'"
    [opened]="!(isMobile | async)"
  >
    <mat-nav-list role="list">
      <ng-container *ngFor="let category of menuItems">
        <a class="nav-a" *ngFor="let item of category" [routerLink]="item.route" [routerLinkActive]="item.route" mat-list-item>
          <mat-icon matListItemIcon style="size: 16px">{{item.icon}}</mat-icon>
          <div mat-line>{{item.label}}</div>
        </a>
        <hr>
      </ng-container>
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content>
    <ng-content></ng-content>
  </mat-drawer-content>
</mat-drawer-container>
