import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'poll-dialog',
  templateUrl: './poll-dialog.component.html',
  styleUrls: ['./poll-dialog.component.scss'],
})
export class PollDialogComponent implements OnInit{
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<PollDialogComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.addOption();
  }

  public form = this.fb.group({
    id: [],
    question: [''],
    options: this.fb.array([]),
    allowMultipleAnswers: [false],
  });

  public get options() {
    return this.form.get('options') as FormArray;
  }

  public addOption() {
    this.options.push(this.fb.control(''));
  }

  public getControl(index: number) {
    return this.options.controls[index] as FormControl;
  }

  public submit() {
    this.ref.close(this.form.value);
  }
}
